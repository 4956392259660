import React from "react";
import { logoPrincipal } from "../../../../utils/constants";

const MainLogo = ({ intl }) => {
  const systemTitle = intl.formatMessage({ id: "LABELS.COMMONS.NOME_SISTEMA" });
  const clientTitle = intl.formatMessage({ id: "LABELS.COMMONS.NOME_CLIENTE" });

  return <img src={logoPrincipal} alt={`${clientTitle} - ${systemTitle}`} />;
};

export default MainLogo;
