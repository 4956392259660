import { createAction, handleActions } from "redux-actions";
import { types as usuarioTypes } from "./usuario";

// ACTION TYPES
const GUEST_USER_CREATE = `${process.env.PUBLIC_URL}/GUEST/CRIAR`;
const GUEST_PASSWORD_RECOVER = `${process.env.PUBLIC_URL}/GUEST/PASSWORD_RECOVER`;
const GUEST_PASSWORD_RECOVER_FINISH = `${process.env.PUBLIC_URL}/GUEST/PASSWORD_RECOVER_FINISH`;
const GUEST_STORE_UPDATE = `${process.env.PUBLIC_URL}/GUEST/STORE_UPDATE`;
const GUEST_INDENTIFICACAO_SEARCH = `${process.env.PUBLIC_URL}/GUEST/IDENTIFICACAO_SEARCH`;
const GUEST_DADOS_COMPLETAR_CADASTRO = `${process.env.PUBLIC_URL}/GUEST/DADOS_COMPLETAR_CADASTRO`;

export const types = {
  GUEST_USER_CREATE,
  GUEST_PASSWORD_RECOVER,
  GUEST_PASSWORD_RECOVER_FINISH,
  GUEST_STORE_UPDATE,
  GUEST_INDENTIFICACAO_SEARCH,
  GUEST_DADOS_COMPLETAR_CADASTRO,
};

// ACTIONS
const criar = createAction(GUEST_USER_CREATE);
const recuperar = createAction(GUEST_PASSWORD_RECOVER);
const redefinir = createAction(GUEST_PASSWORD_RECOVER_FINISH);
const updateStore = createAction(GUEST_STORE_UPDATE);
const verificarIdentificador = createAction(GUEST_INDENTIFICACAO_SEARCH);
const buscarDadosParaCompletarCadastro = createAction(GUEST_DADOS_COMPLETAR_CADASTRO);

export const actions = {
  criar,
  recuperar,
  redefinir,
  updateStore,
  verificarIdentificador,
  buscarDadosParaCompletarCadastro,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [GUEST_STORE_UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [usuarioTypes.USER_LOGIN]: () => initialState,
  },
  initialState,
);

export default reducer;

// SELECTORS
const getVisitante = (state) => state.visitante;

export const selectors = {
  getVisitante,
};
