import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const CLASSE_SEARCH = `${process.env.PUBLIC_URL}/CLASSE/SEARCH`;
const CLASSE_SEARCH_STORE = `${process.env.PUBLIC_URL}/CLASSE/SEARCH_STORE`;

export const types = {
  CLASSE_SEARCH,
  CLASSE_SEARCH_STORE,
};

// ACTIONS
const searchClasse = createAction(CLASSE_SEARCH);
const updateClasse = createAction(CLASSE_SEARCH_STORE);

export const actions = {
  searchClasse,
  updateClasse,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [CLASSE_SEARCH_STORE]: (_, action) => ({
      ...action.payload,
    }),
  },
  initialState,
);

export default reducer;

// SELECTORS
const getClasse = (state) => state.classe.itens;
const getClasseTotalPages = (state) => {
  return state.classe.total;
};

export const selectors = {
  getClasse,
  getClasseTotalPages,
};
