/* eslint-disable */
import SoftplanWebSigner from "../../../../../../commons/websigner/softplan-websigner.js";
import { LACUNA_LICENSE } from "../../../../../../commons/websigner/softplan-websigner-license";

class SoftSigner {
  constructor() {
    this.signer = new SoftplanWebSigner(LACUNA_LICENSE);
  }

  callAsync(func, ...args) {
    return new Promise(
      ((resolve, reject) => {
        this.signer[func](...args)
          .success(resolve)
          .error(reject);
      }).bind(this),
    );
  }

  init = (config) =>
    new Promise((resolve, reject) => {
      this.signer.init({
        ...config,
        ready: resolve,
        defaultError: reject,
      });
    });

  /**
   * @returns {array} returns an array of certificates
   */
  getCertificates = () => this.callAsync("listCertificates");

  /**
   * @returns {base64} returns base64 certificate
   */
  loadCertificate = (thumbprint) => this.callAsync("readCertificate", thumbprint);

  signHash = (obj) =>
    this.callAsync("signHash", {
      digestAlgorithm: "SHA-256",
      ...obj,
    });

  signHashBatch = (obj) =>
    this.callAsync("signHashBatch", {
      digestAlgorithm: "SHA-256",
      ...obj,
    });

  /**
   * @returns {base64} returns base64 certificate
   */
  signData = (obj) =>
    this.callAsync("signData", {
      digestAlgorithm: "SHA-256",
      ...obj,
    });

  redirectToInstallPage = () => {
    this.signer.redirectToInstallPage();
  };

  preauthorizeSignatures = (obj) =>
    this.callAsync("preauthorizeSignatures", {
      ...obj,
    });
}
export default SoftSigner;
