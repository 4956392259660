import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const PROCESSO_FIND = `${process.env.PUBLIC_URL}/PROCESSO/FIND`;
const PROCESSO_FIND_DADOS = `${process.env.PUBLIC_URL}/PROCESSO/FIND_DADOS`;
const PROCESSO_FIND_STORE = `${process.env.PUBLIC_URL}/PROCESSO/FIND_STORE`;
const PROCESSO_FIND_DADOS_STORE = `${process.env.PUBLIC_URL}/PROCESSO/FIND_DADOS_STORE`;
const PROCESSO_FIND_BY_CHAVE_EXTERNA = `${process.env.PUBLIC_URL}/PROCESSO/FIND/CHAVE_EXTERNA`;
const PROCESSO_FIND_LINK_BY_CHAVE_EXTERNA = `${process.env.PUBLIC_URL}/PROCESSO/FIND/LINK/CHAVE_EXTERNA`;
const PROCESSO_DOC_FIND = `${process.env.PUBLIC_URL}/PROCESSO/FIND_DOC`;
const PROCESSO_DOC_FIND_STORE = `${process.env.PUBLIC_URL}/PROCESSO/FIND_STORE_DOC`;
const PROCESSO_RESET = `${process.env.PUBLIC_URL}/PROCESSO/RESET`;
const MATERIALIZACAO_URL = `${process.env.PUBLIC_URL}/PROCESSO/MATERIALIZACAO`;

export const types = {
  PROCESSO_FIND,
  PROCESSO_FIND_DADOS,
  PROCESSO_FIND_DADOS_STORE,
  PROCESSO_FIND_BY_CHAVE_EXTERNA,
  PROCESSO_FIND_LINK_BY_CHAVE_EXTERNA,
  PROCESSO_FIND_STORE,
  PROCESSO_DOC_FIND,
  PROCESSO_DOC_FIND_STORE,
  PROCESSO_RESET,
  MATERIALIZACAO_URL,
};

const findProcesso = createAction(PROCESSO_FIND);
const findProcessoDados = createAction(PROCESSO_FIND_DADOS);
const findMaterializacaoUrl = createAction(MATERIALIZACAO_URL);
const findProcessoByChaveExterna = createAction(PROCESSO_FIND_BY_CHAVE_EXTERNA);
const findProcessoLinkExternoByChaveExterna = createAction(
  PROCESSO_FIND_LINK_BY_CHAVE_EXTERNA,
);
const updateProcesso = createAction(PROCESSO_FIND_STORE);
const updateProcessoDados = createAction(PROCESSO_FIND_DADOS_STORE);
const consultarDocumento = createAction(PROCESSO_DOC_FIND);
const updateDocumento = createAction(PROCESSO_DOC_FIND_STORE);
const reset = createAction(PROCESSO_RESET);

export const actions = {
  findProcesso,
  findProcessoDados,
  findMaterializacaoUrl,
  findProcessoByChaveExterna,
  findProcessoLinkExternoByChaveExterna,
  updateProcesso,
  updateProcessoDados,
  consultarDocumento,
  updateDocumento,
  reset,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [PROCESSO_FIND_STORE]: (state, action) => {
      return {
        ...state,
        processo: action.payload,
      };
    },
    [PROCESSO_FIND_DADOS_STORE]: (state, action) => {
      return {
        ...state,
        listaProcessos: action.payload,
      };
    },
    [PROCESSO_DOC_FIND_STORE]: (state, action) => {
      return {
        ...state,
        processo: action.payload,
      };
    },
    [PROCESSO_RESET]: () => {
      return {
        state: {},
      };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const getProcesso = (state) => {
  return state.processo.processo || {};
};

const getDocumento = (state) => {
  return state.processo.processo || {};
};

const getDadosProcesso = (state) => {
  return state.processo.listaProcessos || { total: 0 };
};

export const selectors = {
  getProcesso,
  getDocumento,
  getDadosProcesso,
};
