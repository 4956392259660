/* eslint-disable no-unused-vars */
import _ from "lodash";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from "./reducers";
import sagas from "./middleware/sagas";

export const configureStore = (history) => {
  // carregando estado anterior da aplicação
  const initialState = loadStateFromLocalStorage();

  // saga
  const sagaMiddleware = createSagaMiddleware();

  const reducerWithRouter = connectRouter(history)(reducers);

  const store = createStore(
    reducerWithRouter,
    initialState,
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), sagaMiddleware),
    ),
  );

  // ouvindo alterações no estado a aplicação
  // invocar, no máximo, a cada 1000ms (throttle)
  store.subscribe(
    _.throttle(() => {
      const usuario = store.getState().usuario;
      saveStateToLocalStorage({
        usuario,
      });
    }, 1000),
  );

  // iniciando as sagas
  let sagaTask = sagaMiddleware.run(function* () {
    yield sagas();
  });

  // hot module replacement
  if (module.hot) {
    // recarregando os reducers
    module.hot.accept("./reducers", () => {
      const nextRootReducer = connectRouter(history)(reducers);
      store.replaceReducer(nextRootReducer);
    });

    // recarregando as sagas
    module.hot.accept("./middleware/sagas", () => {
      sagaTask.cancel();
      sagaTask.done.then(() => {
        sagaTask = sagaMiddleware.run(function* replacedSaga(action) {
          yield sagas();
        });
      });
    });
  }

  return store;
};

// separar?
const LOCAL_STORAGE_KEY = "br.com.softplan.ungp.cpa.portal.solicitacoes";
const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
  } catch (e) {
    // vazio
  }
};
