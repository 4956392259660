/* eslint-disable no-unused-vars */
import _ from "lodash";
import imask from "imask";
import cpf from "cpf";
import { validate as isValidCnpj } from "cnpj";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";

export const CEP_REGEX = /^\d{5}-\d{3}?$/;
export const TELEFONE_REGEX = /\(\d{2}\)\s\d{4,5}-?\d{4}/;
export const NUMERO_ANO = /^\d{4}?$/;

// COMMONS
export const obrigatorio = (value, all, props) => {
  if (value === undefined || isEmpty(value)) {
    return "LABELS.VALIDACAO.CAMPO.OBRIGATORIO";
  }

  if (typeof value === "object" && _.isEmpty(value)) {
    return "LABELS.VALIDACAO.CAMPO.OBRIGATORIO";
  }

  if (typeof value !== "object" && isEmpty(value)) {
    return "LABELS.VALIDACAO.CAMPO.OBRIGATORIO";
  }

  return undefined;
};

// Checkbox
export const checkboxObrigatorio = (value, all, props) => {
  if (!value) {
    return "LABELS.VALIDACAO.CAMPO.OBRIGATORIO";
  }

  return undefined;
};

// CPF
const CPF_MASK = imask.createMask({ mask: "000.000.000-00" });

export const formatCpf = (value, all, props) => {
  return CPF_MASK.resolve(value);
};

export const validateCpf = (value, all, props) => {
  if (value === "000.000.000-00") {
    return undefined;
  }

  if (cpf.isValid(value)) {
    return undefined;
  }

  return "LABELS.VALIDACAO.CAMPO.CPF_INVALIDO";
};

// EMAIL
export const validateEmail = (value, all, props) => {
  if (isEmail(value)) {
    return undefined;
  }

  return "LABELS.VALIDACAO.CAMPO.EMAIL_INVALIDO";
};

export const emailConfirmantion = (value, all, props) => {
  if (value !== all.email) {
    return "E-mail não correspondem";
  }

  return undefined;
};

export const maxLength50Validation = (value, all, props) => {
  if (value.length > 50) {
    return "O campo deve conter no máximo 50 caracteres"
  }

  return undefined;
};

export const maxLength100Validation = (value, all, props) => {
  if (value.length > 100) {
    return "O campo deve conter no máximo 100 caracteres"
  }

  return undefined;
};

// CPF & CNPJ
export const formatCpfCnpj = (value) => {
  if (value.length > 14) {
    return formatCnpj(value);
  }

  return formatCpf(value);
};

export const validateCpfCnpj = (value) => {
  if (value.length > 14) {
    return validateCnpj(value);
  }

  return validateCpf(value);
};

// CNPJ
const CNPJ_MASK = imask.createMask({ mask: "00.000.000/0000-00" });

export const formatCnpj = (value) => {
  return CNPJ_MASK.resolve(value);
};

export const validateCnpj = (value) => {
  if (isValidCnpj(value)) {
    return undefined;
  }

  return "LABELS.VALIDACAO.CAMPO.CNPJ_INVALIDO";
};

// CEP
const CEP_MASK = imask.createMask({ mask: "00000-000" });

export const formatCep = (value) => {
  return CEP_MASK.resolve(value);
};

export const validateCep = (value) => {
  if (CEP_REGEX.test(value)) {
    return undefined;
  }

  return "LABELS.VALIDACAO.CAMPO.CEP_INVALIDO";
};

// TELEFONE
const TELEFONE_MASK = imask.createMask({ mask: "(00) 0000-0000" });
const CELULAR_MASK = imask.createMask({ mask: "(00) 00000-0000" });
// imask aceita array de mask porem nao consegue efetuar o '.resolve'
// sempre valida com a primeira mask configurada
// criadas duas mask para validar o telefone

export const formatRawTelefone = (value) => {
  return value.length > 10 ? CELULAR_MASK.resolve(value) : TELEFONE_MASK.resolve(value);
};

export const formatTelefone = (value) => {
  if (value.length > 14) {
    return CELULAR_MASK.resolve(value);
  }

  return TELEFONE_MASK.resolve(value);
};

export const validateTelefone = (value) => {
  if (value.length > 14) {
    if (TELEFONE_REGEX.test(value)) {
      return undefined;
    }

    return "LABELS.VALIDACAO.CAMPO.TELEFONE_INVALIDO";
  }

  if (TELEFONE_REGEX.test(value)) {
    return undefined;
  }

  return "LABELS.VALIDACAO.CAMPO.TELEFONE_INVALIDO";
};

// ANO
export const validateNuAno = (value, all, props) => {
  if (NUMERO_ANO.test(value)) {
    return undefined;
  }

  return "LABELS.VALIDACAO.CAMPO.EMAIL_INVALIDO";
};

// PASSWORD
export const validatePassword = (value, all, props) => {
  const regex = new RegExp(props.passwordValidation.regex);
  const message = props.passwordValidation.message;
  return regex.test(value) ? undefined : message;
};

export const passwordConfirmation = (value, all, props) => {
  if (value === all.senha || value === all.senhaNova) {
    return undefined;
  }

  return "Senhas não correspondem";
};
