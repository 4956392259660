/* eslint-disable no-unused-vars */
import _ from "lodash";

import { createAction, handleActions } from "redux-actions";

// ACTIONS TYPES
const AREASINTERESSES_FIND_ALL = `${process.env.PUBLIC_URL}/AREASINTERESSES/FIND_ALL`;
const AREASINTERESSES_UPDATE_ALL_STORE = `${process.env.PUBLIC_URL}/AREASINTERESSES/UPDATE_ALL_STORE`;
const SERVICOS_POR_AREAINTERESSE_FIND = `${process.env.PUBLIC_URL}/SERVICOS_POR_AREAINTERESSE_FIND/FIND_SERVICOS`;
const SERVICOS_POR_AREAINTERESSE_UPDATE_ALL_STORE = `${process.env.PUBLIC_URL}/SERVICOS_POR_AREAINTERESSE_FIND/SERVICOS_UPDATE_ALL_STORE`;
const SERVICOS_POR_AREAINTERESSE_CLEAR_ALL_STORE = `${process.env.PUBLIC_URL}/SERVICOS_POR_AREAINTERESSE_FIND/SERVICOS_CLEAR_ALL_STORE`;

export const types = {
  AREASINTERESSES_FIND_ALL,
  AREASINTERESSES_UPDATE_ALL_STORE,
  SERVICOS_POR_AREAINTERESSE_FIND,
  SERVICOS_POR_AREAINTERESSE_UPDATE_ALL_STORE,
  SERVICOS_POR_AREAINTERESSE_CLEAR_ALL_STORE,
};

// ACTIONS
const findAllAreasInteresses = createAction(AREASINTERESSES_FIND_ALL);
const updateAllAreasInteressesStore = createAction(
  AREASINTERESSES_UPDATE_ALL_STORE,
);
const findServicosPorAreaInteresse = createAction(
  SERVICOS_POR_AREAINTERESSE_FIND,
);
const updateServicosPorAreaInteresseStore = createAction(
  SERVICOS_POR_AREAINTERESSE_UPDATE_ALL_STORE,
);
const limpaServicosPorAreaInteresseStore = createAction(
  SERVICOS_POR_AREAINTERESSE_CLEAR_ALL_STORE,
);

export const actions = {
  findAllAreasInteresses,
  updateAllAreasInteressesStore,
  findServicosPorAreaInteresse,
  updateServicosPorAreaInteresseStore,
  limpaServicosPorAreaInteresseStore,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [AREASINTERESSES_UPDATE_ALL_STORE]: (state, action) => {
      return {
        ...state,
        areas: action.payload,
      };
    },
    [SERVICOS_POR_AREAINTERESSE_UPDATE_ALL_STORE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [SERVICOS_POR_AREAINTERESSE_CLEAR_ALL_STORE]: (state, action) => {
      const servicos = [];

      return {
        ...state,
        servicos,
        areaInteresse: {},
      };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const getAreasInteresses = (state) => filterAreasInteresses(state);
const getServicosPorAreaInteresse = (state) => findServicos(state);
const getNuPaginaTotal = (state) => nuPaginaTotal(state);

const filterAreasInteresses = (state) => {
  return _.flatMap(_.values(state.areasInteresses.areas));
};

const findServicos = (state) => {
  return state.areasInteresses;
};

const nuPaginaTotal = (state) => {
  return state.areasInteresses.qtPagina;
};

export const selectors = {
  getAreasInteresses,
  getServicosPorAreaInteresse,
  getNuPaginaTotal,
};
