import { all, call, put, takeEvery } from "redux-saga/effects";
import solicitacoesService from "../../commons/services/solicitacoes";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/solicitacoes";
import { delay } from "redux-saga";

export default function* solicitacoesSaga() {
  yield takeEvery(types.SOLICITACAO_FIND, find);
  yield takeEvery(types.SOLICITACAO_FIND_ALL, findAll);
  yield takeEvery(types.SOLICITACAO_FINALIZAR, finalizar);
  yield takeEvery(types.SOLICITACAO_LIST, list);
}

function* find(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => solicitacoesService.findSolicitacao(action.payload || [])),
      ]);

      yield put(actions.updateSolicitacaoStore(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }

    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* findAll(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => solicitacoesService.pendencias(action.payload || [])),
      ]);

      yield put(actions.updateAllSolicitacaoStore(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* finalizar(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => solicitacoesService.finalizar(action.payload)),
      ]);

      yield put(actions.updateSolicitacaoStore(data));
    },
  });
}

function* list(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => solicitacoesService.list(action.payload || [])),
        yield call(delay, 2000),
      ]);

      yield put(actions.updateListSolicitacoes(data));
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}
