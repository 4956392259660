import { all, call, put, takeEvery } from "redux-saga/effects";
import interessadoService from "../../commons/services/interessado";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/interessado";

export default function* interessadoSaga() {
  yield takeEvery(types.INTERESSADO_SEARCH, searchInteressado);
}

function* searchInteressado(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => interessadoService.searchInteressado(action.payload || [])),
      ]);

      yield put(actions.updateInteressado(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}
