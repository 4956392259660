import axios from "../http/axios";

export default {
  searchAddress: (data) => {
    return axios
      .get(`/v1/endereco/${data.cep}`, {
        headers: { "Cache-Control": "no-cache" },
      })
      .then((res) => {
        return res.data;
      });
  },
};
