import { createAction, handleActions } from "redux-actions";

// ACTIONS TYPES
const SERVICO_FIND = `${process.env.PUBLIC_URL}/SERVICO/FIND`;
const SERVICO_UPDATE_ALL_STORE = `${process.env.PUBLIC_URL}/SERVICO/UPDATE_ALL_STORE`;
const SERVICO_FIND_FORMULARIO = `${process.env.PUBLIC_URL}/SERVICO/FIND_FORMULARIO`;

export const types = {
  SERVICO_FIND,
  SERVICO_UPDATE_ALL_STORE,
  SERVICO_FIND_FORMULARIO,
};

// ACTIONS
const findServico = createAction(SERVICO_FIND);
const updateServicoStore = createAction(SERVICO_UPDATE_ALL_STORE);
const findFormularioServico = createAction(SERVICO_FIND_FORMULARIO);

export const actions = {
  findServico,
  updateServicoStore,
  findFormularioServico,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [SERVICO_UPDATE_ALL_STORE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const getServico = (state) => state.servicos;
const getFormularioServico = (state) => state.servicos;

export const selectors = {
  getServico,
  getFormularioServico,
};
