import { all, call, put, takeEvery } from "redux-saga/effects";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/servicos";
import servicoService from "../../commons/services/servicos";

export default function* servicosSaga() {
  yield takeEvery(types.SERVICO_FIND, findServico);
  yield takeEvery(types.SERVICO_FIND_FORMULARIO, findFormularioServico);
}

function* findServico(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => servicoService.findServico(action.payload)),
      ]);

      yield put(actions.updateServicoStore(data));
    },
  });
}

function* findFormularioServico(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => servicoService.findFormularioServico(action.payload)),
      ]);

      yield put(actions.updateServicoStore(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },
  });
}
