import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const INTERESSADO_SEARCH = `${process.env.PUBLIC_URL}/INTERESSADO/SEARCH`;
const INTERESSADO_SEARCH_STORE = `${process.env.PUBLIC_URL}/INTERESSADO/SEARCH_STORE`;

export const types = {
  INTERESSADO_SEARCH,
  INTERESSADO_SEARCH_STORE,
};

// ACTIONS
const searchInteressado = createAction(INTERESSADO_SEARCH);
const updateInteressado = createAction(INTERESSADO_SEARCH_STORE);

export const actions = {
  searchInteressado,
  updateInteressado,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [INTERESSADO_SEARCH_STORE]: (_, action) => ({
      ...action.payload,
    }),
  },
  initialState,
);

export default reducer;

// SELECTORS
const getInteressado = (state) => state.interessado;

export const selectors = {
  getInteressado,
};
