import axios from "../http/axios";

export default {
  searchAssunto: (payload) => {
    return axios
      .get(
        `/v1/consultarAssunto?numero=${payload.nuAssunto}&nome=${payload.nmAssunto}&pagina=${payload.pagina}`,
      )
      .then((res) => {
        return res.data;
      });
  },
};
