import { all, call, takeEvery } from "redux-saga/effects";
import { flow } from "../modules/flow";
import { types } from "../modules/file";
import fileService from "../../commons/services/file";

export default function* fileSaga() {
  yield takeEvery(types.FILE_FIND, findFile);
}

function* findFile(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      yield all([call(() => fileService.findFile(action.payload))]);
    },
  });
}
