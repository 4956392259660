import _ from "lodash";

import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const OPEN = `${process.env.PUBLIC_URL}/MODAL/OPEN`;
const CLOSE = `${process.env.PUBLIC_URL}/MODAL/CLOSE`;

export const types = {
  OPEN,
  CLOSE,
};

// ACTIONS
const open = createAction(OPEN);
const close = createAction(CLOSE);

export const actions = {
  open,
  close,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [OPEN]: (state, action) => {
      const next = _.size(state) + 1;
      return { ...state, [next]: { id: next, ...action.payload } };
    },
    [CLOSE]: (state, action) => _.omit(state, action.payload.id),
  },
  initialState,
);

export default reducer;

// SELECTORS
const getModals = (state) => state.modal;

export const selectors = {
  getModals,
};
