import axios from "axios";
import { backendBaseURL } from "../utils/constants/services/services-constants";
import { ROUTES } from "../commons/routes/routes";

import {
  actions as usuarioActions,
} from "../redux/modules/usuario";

const serviceTaskAPI = axios.create({
  baseURL: backendBaseURL,
});

export default serviceTaskAPI;

export const registerServiceTaskApiInterceptors = (store, history) => {
  serviceTaskAPI.interceptors.response.use(
    // success
    (response) => response,
  
    // error
    (error) => {
  
      const errorHasResponse = Boolean(error && error.response);
  
      if (errorHasResponse && error.response.status === 401) {
        // token já não está mais autorizado
        if ("/logout" !== error.response.config.url) {
          store.dispatch(usuarioActions.forceLogoutUser());
        }
      } else if (errorHasResponse && error.response.status === 429) {
        history.push(ROUTES.STATUS_429.path)
      }
  
      return Promise.reject(error);
    },
  );
};