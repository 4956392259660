import { all, call, put, takeEvery, take, fork } from "redux-saga/effects";
import pagamentosService from "../../commons/services/pagamentos";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/pagamentos";
import { actions as modalActions } from "../../redux/modules/modal";
import { eventChannel, END } from "redux-saga";

export default function* pagamentosSaga() {
  yield takeEvery(types.PAGAMENTO_FIND, find);
  yield takeEvery(types.PAGAMENTO_LOAD_MORE, loadMore);
  yield takeEvery(types.FINALIZAR, finalizarPagamento);
  yield takeEvery(types.DADOS_PAGAMENTO, buscarPagamento);
  yield takeEvery(types.ATRIBUIR, atribuirPagamento);
  yield takeEvery(types.DOCUMENTO, buscarUrlDocumento);
  yield takeEvery(types.DOCUMENTO_ANEXAR, anexarDocumento);
  yield takeEvery(types.CONFIRMACAO_LEITURA, confirmarLeitura);
}

function* find(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => pagamentosService.find(action.payload || [])),
      ]);

      yield put(actions.updatePagamentos(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* loadMore(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => pagamentosService.loadMore(action.payload || [])),
      ]);

      yield put(actions.updateLoadMore(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* finalizarPagamento(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => pagamentosService.finalizar(action.payload)),
      ]);

      yield put(actions.updateFinalizarPagamento(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },
  });
}

function* buscarPagamento(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => pagamentosService.buscarPagamento(action.payload || [])),
      ]);

      yield put(actions.updateBuscarPagamento(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* atribuirPagamento(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => pagamentosService.atribuir(action.payload)),
      ]);

      yield put(actions.updateAtribuirPagamento(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },
  });
}

function* buscarUrlDocumento(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => pagamentosService.buscarUrlDocumento(action.payload || [])),
      ]);

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* uploadProgressWatcher(chan, payload) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const progress = yield take(chan);
    yield put(
      actions.progressoAnexarDocumento({
        ...payload,
        progress: progress,
      }),
    );
  }
}

function createUploader(action) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: uploadProgressCb,
  };

  const uploadPromise = pagamentosService.anexarDocumento(
    action.payload || [],
    config,
  );
  return [uploadPromise, chan];
}

function* anexarDocumento(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [uploadPromise, chan] = yield call(createUploader, action);
      yield fork(uploadProgressWatcher, chan, action.payload);
      const res = yield call(() => uploadPromise);
      // callback
      const { callback } = action.payload;
      if (callback && typeof callback === "function") {
        callback(res.data);
      }
    },

    fnCatch: function* () {
      const { errorCallback } = action.payload;
      if (errorCallback && typeof errorCallback === "function") {
        yield errorCallback();
      }
    },
  });
}

function* confirmarLeitura(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => pagamentosService.confirmarLeitura(action.payload || [])),
      ]);

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      yield put(
        modalActions.open({
          title: "LABELS.COMMONS.CONFIRMACAO_LEITURA.ERRO.TITULO",
          content: "LABELS.COMMONS.CONFIRMACAO_LEITURA.ERRO.DESCRICAO",
          params: {},
        }),
      );
    },
  });
}
