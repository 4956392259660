import axios from "../http/axios";

export default {
  find: (payload) => {
    let urlFiltros = "";
    if (payload.pagina) {
      urlFiltros += `&pagina=${payload.pagina}`;
    }
    if (payload.quantidadePorPagina) {
      urlFiltros += `&quantidadePorPagina=${payload.quantidadePorPagina}`;
    }
    if (payload.dtPrazoInicio) {
      urlFiltros += `&dtPrazoInicio=${payload.dtPrazoInicio}`;
    }
    if (payload.dtPrazoFim) {
      urlFiltros += `&dtPrazoFim=${payload.dtPrazoFim}`;
    }
    if (payload.situacoes) {
      urlFiltros += `&situacoes=${payload.situacoes}`;
    }
    if (payload.sgsTiposTarefaEspecializada) {
      urlFiltros += `&sgsTiposTarefaEspecializada=${payload.sgsTiposTarefaEspecializada}`;
    }
    if (payload.cdTarefa) {
      urlFiltros += `&cdTarefa=${payload.cdTarefa}`;
    }
    if (payload.ordenacao) {
      urlFiltros += `&ordenacao=${payload.ordenacao}`;
    }
    if(urlFiltros){
    	urlFiltros = urlFiltros.replace('&', '?');
    }

    return axios
      .get(`/minhas-pendencias${urlFiltros}`)
      .then((res) => {
        return res.data;
      });
  },
  loadMore: (payload) => {
    return axios
      .get(
        `/minhas-pendencias?pagina=${payload.pagina}&quantidadePorPagina=${payload.quantidadePorPagina}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  buscarPendencia: (payload) => {
    return axios
      .get(
        `/minhas-pendencias/${payload.cdProcesso}/tarefa/${payload.cdTarefa}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  atribuir: (payload) => {
    return axios
      .put(
        `/minhas-pendencias/tarefa/${payload.cdTarefa}/atribuir`,
        payload.body,
      )
      .then((res) => {
        return res.data;
      });
  },
  finalizar: (payload) => {
    return axios
      .put(
        `/minhas-pendencias/${payload.cdProcesso}/tarefa/${payload.cdTarefa}/finalizar`,
        payload.body,
      )
      .then((res) => {
        return res.data;
      });
  },
  buscarUrlDocumento: (payload) => {
    return axios
      .get(
        `/minhas-pendencias/tarefa/${payload.cdTarefa}/documento-pendente-assinatura/${payload.cdDocumento}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  anexarDocumento: (payload, config) => {
    const formData = new FormData();
    formData.append("files", payload.file);
    return axios.post(`/godoc/archiving`, formData, config);
  },
  confirmarLeitura: (payload) => {
    return axios
      .put(`/minhas-pendencias/tarefa/${payload.cdTarefa}/confirma-leitura`)
      .then((res) => {
        return res.data;
      });
  },
};
