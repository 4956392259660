import axios from "../http/axios";

export default {
  searchClasse: (payload) => {
    return axios
      .get(
        `/v1/consultarClasse?numero=${payload.nuClasse}&nome=${payload.nmClasse}&pagina=${payload.pagina}`,
      )
      .then((res) => {
        return res.data;
      });
  },
};
