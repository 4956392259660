import { all, call, put, takeEvery } from "redux-saga/effects";
import orgaoService from "../../commons/services/orgao";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/orgao";

export default function* orgaoSaga() {
  yield takeEvery(types.ORGAO_SEARCH, searchOrgao);
}

function* searchOrgao(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => orgaoService.searchOrgao(action.payload || [])),
      ]);

      yield put(actions.updateOrgao(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}
