import { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";

class DateFormat extends PureComponent {
  render() {
    const { value, format } = this.props;

    return moment(value).format(format);
  }
}

DateFormat.propTypes = {
  value: PropTypes.string.isRequired,
};

DateFormat.defaultProps = {
  format: "DD/MM/YYYY",
};

export default DateFormat;
