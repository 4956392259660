import { createAction, handleActions } from "redux-actions";

// ACTIONS TYPES
const FILE_FIND = `${process.env.PUBLIC_URL}/FILE/FIND`;

export const types = {
  FILE_FIND,
};

// ACTIONS
const findFile = createAction(FILE_FIND);

export const actions = {
  findFile,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions({}, initialState);

export default reducer;
