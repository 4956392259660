import { all, takeEvery, put, call } from "redux-saga/effects";

import { flow } from "../modules/flow";
import { types, actions } from "../modules/visitante";
import usuarioService from "../../commons/services/usuario";

export default function* recuperarSenhaSaga() {
  yield takeEvery(types.GUEST_USER_CREATE, criarContaAsync);
  yield takeEvery(types.GUEST_PASSWORD_RECOVER, recuperarSenhaAsync);
  yield takeEvery(types.GUEST_PASSWORD_RECOVER_FINISH, redefinirSenha);
  yield takeEvery(types.GUEST_INDENTIFICACAO_SEARCH, verificarIdentificador);
  yield takeEvery(types.GUEST_DADOS_COMPLETAR_CADASTRO, buscarDadosParaCompletarCadastro);
}

function* recuperarSenhaAsync(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => usuarioService.recuperarSenha(action.payload)),
      ]);

      yield put(actions.updateStore({ email: data.deEmail }));
    },
  });
}

function* criarContaAsync(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      yield all([call(() => usuarioService.cadastro(action.payload))]);

      yield put(actions.updateStore({ ...action.payload }));
    },
  });
}

function* redefinirSenha(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() =>
          usuarioService.redefinirSenha(
            action.payload.token,
            action.payload.body,
          ),
        ),
      ]);

      yield put(actions.updateStore({ email: data.deEmail }));
    },
  });
}

function* verificarIdentificador(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => usuarioService.verificarIdentificador(action.payload)),
      ]);

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* buscarDadosParaCompletarCadastro(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => usuarioService.buscarDadosParaCompletarCadastro(action.payload))
      ]);

      const { callback } = action.payload;

      if (callback && typeof callback === "function") {
        callback(data);
      }
    },
  });
}
