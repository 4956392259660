import React, { PureComponent } from "react";
import { compose } from "redux";
import { injectIntl, FormattedHTMLMessage } from "react-intl";

class Status404 extends PureComponent {

  componentDidMount() {
    document.getElementById("skip-to-content").focus();
  }

  render() {
    return (
      <section
        className="sds-page-centered"
        aria-label="Página não encontrada"
        aria-live="assertive"
        role="alert"
      >
        <img
          className="sds-margin-vertical"
          width="272px"
          height="179px"
          src={`${process.env.PUBLIC_URL}/assets/imgs/404.svg`}
          alt=""
        />
        <div className="sds-container">
          <p className="sds-p">
            <FormattedHTMLMessage id="LABELS.MESSAGES.COMMONS.STATUS_404" />
          </p>
        </div>
      </section>
    );
  }
}

export default compose(injectIntl)(Status404);
