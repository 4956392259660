import axios from "../http/axios";

export default {
  findFile: (data) => {
    return axios
      .get(`/v1/documentos/${data.codigo}`, {
        headers: { "Cache-Control": "no-cache" },
        responseType: "blob",
      })
      .then((res) => {
        if (res.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/octet-stream" }),
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.headers.filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  },
};
