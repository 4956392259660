import { Base64 } from "js-base64";

export const TabTitle = (newTitle) => {
  return (document.title = newTitle);
};

export const interceptorRequest = (url) => {
  return url.interceptors.request.use(function (config) {
    const storedData = JSON.parse(localStorage.getItem("br.com.softplan.ungp.cpa.portal.solicitacoes"));
    const userActive = storedData.usuario.perfils.find((perfil) => perfil.active);

    config.headers.Authorization = userActive.token ? `Bearer ${userActive.token}` : "";

    return config;
  });
};

export const getCookieDecoded = (sessionIdBase64) => {
  if (sessionIdBase64) {
    return Base64.decode(sessionIdBase64);
  }
  return null;
};

export const simpleSetCookie = (variable) => {
  document.cookie = variable + "; path=/";
};

export function focusOnButton(prevProps, botaoAbrirModalRef) {
  if (Object.keys(prevProps.modal).length !== 0 && prevProps.modal[1].close) {
    botaoAbrirModalRef.current.focus();
  }
}

export function handleModalState(prevState, state, setState, modalRef, modalName) {
  if (prevState.showModal === true && state.showModal === false) {
    if (state[modalName] === true) {
      setState({ [modalName]: false }, () => {
        modalRef.current.focus();
      });
    }
  }
}

export function handleFocusOutGlobal(e, input, msgAlert) {
  e.preventDefault();

  const mensagem = document.getElementById("mensagem");
  const feedback = document.getElementById("feedback");

  if (!input.value) {
    mensagem.innerHTML = "";
    feedback.removeAttribute("role");
    feedback.setAttribute("role", "alert");
    mensagem.innerHTML = msgAlert;
  } else {
    mensagem.innerHTML = "";
    feedback.removeAttribute("role");
  }
}

export function handleVerifyEmptyField(e, intl, field) {
  const msgEmptyIdField = `O campo ${intl.formatMessage({
    id: field,
  })} é de preenchimento obrigatório`;

  handleFocusOutGlobal(e, e.target, msgEmptyIdField);
}

