/* eslint-disable no-class-assign */
import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  types as processoTypes,
  actions as processoActions,
  selectors as processoSelectors,
} from "../../../redux/modules/processo";
import {
  actions as flowActions,
  selectors as flowSelectors,
} from "../../../redux/modules/flow";
import DateFormat from "../../../commons/components/DateFormat/DateFormat";
import Separator from "../../../commons/components/Separator";
import AppLoading from "../../AppLayout/containers/AppLoading";
import "./Processo.css";
import { Link, Button } from "@material-ui/core";
import axios from "../../../commons/http/axios";

const TAB_DADO = 0;
const TAB_DOCUMENTO = 1;
const interessadoColumns = ["CPF/CNPJ", "Matrícula", "Nome"];
const tramitacoesColumns = [
  "Vol.",
  "Órgão/Setor",
  "Recebido em",
  "Encaminhado em",
  "Parecer",
];

class ProcessoGVE extends PureComponent {
  state = {
    selectedTab: TAB_DADO,
  };

  componentDidMount() {
    const { chaveExterna } = this.props;

    let chaveTemp = this.props.match
      ? this.props.match.params.id
      : chaveExterna;

    if (!chaveTemp) {
      chaveTemp = window.location.href.split("processo/v1/id/")[1];
    }

    this.props.consultarProcessoLinkExterno({
      cdLinkExterno: chaveTemp,
    });
    this.props.reset(processoTypes.PROCESSO_FIND);
  }

  componentWillUnmount() {
    this.props.resetStore();
  }

  handleTab = (e) => {
    const id = parseInt(e.currentTarget.id, 0);
    this.setState({ selectedTab: id === TAB_DADO ? TAB_DADO : TAB_DOCUMENTO });
  };

  renderTabs = (state, handleTab) => {
    return (
      <Tabs
        value={state.selectedTab}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          id={TAB_DADO}
          onClick={handleTab}
          style={{ textTransform: "initial", fontSize: "14px" }}
          label={"Dados Básicos"}
        />
        <Tab
          id={TAB_DOCUMENTO}
          onClick={handleTab}
          style={{ textTransform: "initial", fontSize: "14px" }}
          label={"Documentos"}
        />
      </Tabs>
    );
  };

  renderTabDado = ({ data, interessadoColumns, tramitacoesColumns }) => {
    return (
      <div className="processo__tab-content--padding">
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>Tipo:</span>
          </div>
          <div>{data.tipo}</div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>Data de entrada:</span>
          </div>
          <div>{data.dtEntrada && <DateFormat value={data.dtEntrada} />}</div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>Orgão de abertura:</span>
          </div>
          <div>{data.orgaoAbertura}</div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>Setor de abertura:</span>
          </div>
          <div>{data.setorAbertura}</div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>Orgão atual:</span>
          </div>
          <div>{data.orgaoAtual}</div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>Setor atual:</span>
          </div>
          <div>{data.setorAtual}</div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>Classe:</span>
          </div>
          <div>{data.classe}</div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>Assunto:</span>
          </div>
          <div>{data.nmAssunto}</div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>Recebido em:</span>
          </div>
          <div>
            {data.dtRecebimento && <DateFormat value={data.dtRecebimento} />}
          </div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>
              <FormattedMessage id="LABELS.COMMONS.SOLICITACAO.SITUACAO" />
            </span>
          </div>
          <div>{data.estado}</div>
        </div>
        <div className="processo__tab-content--item">
          <div className="processo__tab-content--item-title">
            <span>
              <FormattedMessage id="LABELS.COMMONS.SOLICITACAO.DETALHAMENTO" />
            </span>
          </div>
          <div>{data.detalhamentoComplemento}</div>
        </div>

        <div className="processo__tab-content--subtitle">
          <div>
            <span>Interessados</span>
          </div>
          <div className="sds-data-table__container sds-data-table__container--card">
            <div className="sds-data-table__wrapper">
              <table className="sds-data-table sds-data-table--large">
                <thead>
                  <tr>
                    {interessadoColumns.map((column) => {
                      return (
                        <th
                          key={column}
                          className="sds-data-table__cell-non-numeric"
                        >
                          <div>
                            <span>{column}</span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.interessados &&
                    data.interessados.map((data) => {
                      return (
                        <tr key={data.identificacao}>
                          <td className="sds-data-table__cell-non-numeric">
                            <span>{data.identificacao}</span>
                          </td>
                          <td className="sds-data-table__cell-non-numeric">
                            <span>{data.matricula}</span>
                          </td>
                          <td className="sds-data-table__cell-non-numeric">
                            <span>{data.nome}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="processo__tab-content--subtitle">
          <div>
            <span>Tramitações</span>
          </div>
          <div className="sds-data-table__container sds-data-table__container--card">
            <div className="sds-data-table__wrapper">
              <table className="sds-data-table sds-data-table--large">
                <thead>
                  <tr>
                    {tramitacoesColumns.map((column) => {
                      return (
                        <th
                          key={column}
                          className="sds-data-table__cell-non-numeric"
                        >
                          <div>
                            <span>{column}</span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.tramitacoes &&
                    data.tramitacoes.map((data) => {
                      return (
                        <tr key={`${data.nuProcesso}-${data.nuTramite}`}>
                          <td className="sds-data-table__cell-non-numeric">
                            <span>{data.nuVolume}</span>
                          </td>
                          <td className="sds-data-table__cell-non-numeric">
                            <span>{data.sgOrgaotrami}</span>
                          </td>
                          <td className="sds-data-table__cell-non-numeric">
                            <span>
                              {data.dtRecebto && (
                                <DateFormat value={data.dtRecebto} />
                              )}
                            </span>
                          </td>
                          <td className="sds-data-table__cell-non-numeric">
                            <span>
                              {data.dtEncaminha && (
                                <DateFormat value={data.dtEncaminha} />
                              )}
                            </span>
                          </td>
                          <td className="sds-data-table__cell-non-numeric">
                            {<span>{data.deParecer}</span>}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  download() {
    const { chaveExterna } = this.props;
    let processo = {
      chave_externa: this.props.match
        ? this.props.match.params.id
        : chaveExterna,
    };

    axios
      .post(`/v1/solicitacao/consultarMaterializacao`, processo)
      .then((res) => {
        if (res.status === 200) {
          const link = document.createElement("a");
          link.href = res.data;
          document.body.appendChild(link);
          link.click();
        }
      });
  }

  renderTabDocumento = (data) => {
    return (
      <Grid>
        <Grid style={{ height: 790 }}>
          <iframe
            id="documento-processo"
            ref={(f) => (this.ifr = f)}
            src={data.documentoIframe}
            title={"documento-processo"}
            width="100%"
            height="100%"
            frameBorder="0"
            className="processo__tab-iframe"
          />
        </Grid>
        <Grid
          style={{
            minWidth: 64,
            marginLeft: "auto",
            width: "89px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Link
            onClick={() => {
              this.download(data);
            }}
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ marginLeft: "auto" }}
            >
              Imprimir
            </Button>
          </Link>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { data, loading } = this.props;

    return (
      <div style={{ background: "white" }}>
        <AppLoading show={loading} msg="Consultando processo..." />

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          className="processo__header-content--padding"
        >
          <Grid item>
            <Typography variant="h5" align="center" gutterBottom>
              {data.titulo}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="center">
              <FormattedMessage id="LABELS.COMMONS.SOLICITACAO.SOLICITACAO_NUMERO" />
              &nbsp;{data.numero}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" align="center">
              <FormattedMessage id="LABELS.COMMONS.SOLICITACAO.SOLICITADO_EM" />
              &nbsp;{data.dtEntrada && <DateFormat value={data.dtEntrada} />}
            </Typography>
          </Grid>
        </Grid>

        {this.renderTabs(this.state, this.handleTab)}

        <Separator isFullWidth={true} />

        {this.state.selectedTab === TAB_DADO &&
          this.renderTabDado({ data, interessadoColumns, tramitacoesColumns })}

        {this.state.selectedTab === TAB_DOCUMENTO &&
          this.renderTabDocumento(data)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: flowSelectors.isLoadingByType(
      state,
      processoTypes.PROCESSO_FIND_LINK_BY_CHAVE_EXTERNA,
    ),

    data: processoSelectors.getProcesso(state),
  };
}

const mapDispatchToProps = {
  consultarProcesso: processoActions.findProcessoByChaveExterna,
  resetStore: processoActions.reset,
  reset: flowActions.reset,
  consultarProcessoLinkExterno:
    processoActions.findProcessoLinkExternoByChaveExterna,
};

ProcessoGVE.propTypes = {
  chaveExterna: PropTypes.string,
};

ProcessoGVE = connect(mapStateToProps, mapDispatchToProps)(ProcessoGVE);

export default ProcessoGVE;
