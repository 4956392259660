import _ from "lodash";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import classnames from "classnames";
import { injectIntl, FormattedMessage } from "react-intl";
import { actions, selectors } from "../../redux/modules/modal";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  state = {
    open: true,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    window.addEventListener("onGdocClosed", this.onGdocClosed);
    window.addEventListener("onGdocSuccessed", this.onGdocSuccessed);
  }

  componentWillUnmount() {
    window.removeEventListener("onGdocClosed", this.onGdocClosed);
    window.removeEventListener("onGdocSuccessed", this.onGdocSuccessed);
  }

  onGdocClosed = () => {
    // FIXME research better way?!
    this.props.close(this.props.modal[1]);
  };

  onGdocSuccessed = () => {
    this.onGdocClosed();
    var e = new Event("onGdocSuccessedFinalizacao");
    dispatchEvent(e);
  };

  handleDialogClose(modal) {
    typeof modal.close === "function"
      ? modal.close(modal)
      : this.props.close(modal);
  }

  renderModals() {
    return _.map(this.props.modal, (m) => {
      const isTitleString = typeof m.title === "string";
      const isContentString = typeof m.content === "string";

      return (
        <React.Fragment>
          <Dialog
            open={true}
            onClose={() => this.handleDialogClose(m)}
            className={classnames("sds-modal-wrapper sds-modal-wrapper--gray", {
              "sds-modal-wrapper--show": this.state.show,
            })}
            key={m.id}
            maxWidth="false"
          >
            <div className="sds-modal sds-modal--form" style={{ margin: 0 }}>
              <MuiDialogTitle disableTypography>
                <div className="sds-modal__header">
                  <div className="sds-subtitle-small">
                    {isTitleString && (
                      <h2
                        ref={(ref) => {
                          if (ref) {
                            ref.focus();
                          }
                        }}
                        aria-label={m.title}
                        tabIndex={-1}
                      >
                        <FormattedMessage id={m.title} values={m.params} />
                        {!isTitleString && m.title}
                      </h2>
                    )}
                  </div>
                  <button
                    className="sds-btn sds-btn--flat sds-btn--flat-secondary sds-modal-btn__desktop"
                    onClick={() => this.handleDialogClose(m)}
                  >
                    Fechar
                    <i
                      className="material-icons sds-btn__icon sds-btn--right"
                      aria-hidden="true"
                    >
                      close
                    </i>
                  </button>
                  <button
                    className="sds-btn-icon sds-btn-icon--flat sds-modal-btn__mobile"
                    onClick={() => this.handleDialogClose(m)}
                  >
                    <i className="material-icons" aria-hidden="true">
                      close
                    </i>
                  </button>
                </div>
              </MuiDialogTitle>
              <MuiDialogContent>
                <div className="sds-modal__body">
                  {isContentString && (
                    <FormattedMessage id={m.content} values={m.params} />
                  )}
                  {!isContentString && m.content}
                </div>
              </MuiDialogContent>
            </div>
          </Dialog>
        </React.Fragment>
      );
    });
  }

  render() {
    if (_.isEmpty(this.props.modal)) {
      return null;
    }

    setTimeout(() => {
      this.setState({ show: true });
    }, 0);

    return <div>{this.renderModals()}</div>;
  }
}

Modal.propTypes = {
  modal: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modal: selectors.getModals(state),
  };
}

const mapDispatchToProps = {
  close: actions.close,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(Modal);
