import { all, call, put, takeEvery } from "redux-saga/effects";
import cfgService from "../../commons/services/configuracoes";
import { actions, types } from "../modules/configuracoes";
import { flow } from "../modules/flow";

export default function* configuracoesSaga() {
  yield takeEvery(types.CARREGAR, carregar);
  yield takeEvery(types.ATUALIZAR_JSON, atualizarCfgJson);
  yield takeEvery(types.ATUALIZAR_TEXTO, atualizarCfgTexto);
  yield takeEvery(types.ATUALIZAR_ARQUIVO, atualizarCfgArquivo);
  yield takeEvery(types.ATUALIZAR_EMAIL, atualizarCfgEmail);
  yield takeEvery(
    types.CARREGAR_CONFIGURACOES_INICIAIS,
    carregarConfiguracoesIniciais,
  );
}

function* carregar(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const cfg = yield call(
        cfgService.carregar,
        action.payload || { chaves: [], campos: [] },
      );

      yield put(actions.atualizarStore(cfg));
    },
  });
}

function* atualizarCfgJson(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [cfg] = yield all([
        call(
          cfgService.atualizar,
          "json",
          action.payload.chave,
          action.payload.body,
        ),
      ]);

      yield put(actions.atualizarStore(cfg));
    },
  });
}

function* atualizarCfgTexto(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [cfg] = yield all([
        call(
          cfgService.atualizar,
          "texto",
          action.payload.chave,
          action.payload.body,
          { "Content-Type": "text/plain" },
        ),
      ]);

      yield put(actions.atualizarStore(cfg));
    },
  });
}

function* atualizarCfgEmail(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [cfg] = yield all([
        call(
          cfgService.atualizar,
          "email",
          action.payload.chave,
          action.payload.body,
        ),
      ]);

      yield put(actions.atualizarStore(cfg));
    },
  });
}

function* atualizarCfgArquivo(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [cfg] = yield all([
        call(
          cfgService.atualizar,
          "arquivo",
          action.payload.chave,
          action.payload.body,
        ),
      ]);

      yield put(actions.atualizarStore(cfg));
    },
  });
}

function* carregarConfiguracoesIniciais(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const cfgs = yield call(cfgService.carregarConfiguracoesIniciais);

      yield put(actions.atualizarStore(cfgs));
    },
  });
}
