import _ from "lodash";

import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const PAGAMENTO_FIND = `${process.env.PUBLIC_URL}/PAGAMENTO/FIND`;
const PAGAMENTO_FIND_RESET = `${process.env.PUBLIC_URL}/PAGAMENTO/FIND_RESET`;
const PAGAMENTO_UPDATE_STORE = `${process.env.PUBLIC_URL}/PAGAMENTO/UPDATE_STORE`;
const PAGAMENTO_LOAD_MORE = `${process.env.PUBLIC_URL}/PAGAMENTO/LOAD_MORE`;
const PAGAMENTO_LOAD_MORE_UPDATE_STORE = `${process.env.PUBLIC_URL}/PAGAMENTO/LOAD_MORE_UPDATE_STORE`;
const DADOS_PAGAMENTO = `${process.env.PUBLIC_URL}/PAGAMENTO/DADOS`;
const DADOS_UPDATE_STORE = `${process.env.PUBLIC_URL}/PAGAMENTO/DADOS_UPDATE_STORE`;
const FINALIZAR = `${process.env.PUBLIC_URL}/PAGAMENTO/FINALIZAR`;
const FINALIZAR_UPDATE_STORE = `${process.env.PUBLIC_URL}/PAGAMENTO/FINALIZAR_UPDATE_STORE`;
const ATRIBUIR = `${process.env.PUBLIC_URL}/PAGAMENTO/ATRIBUIR`;
const ATRIBUIR_UPDATE_STORE = `${process.env.PUBLIC_URL}/PAGAMENTO/ATRIBUIR_UPDATE_STORE`;
const DOCUMENTO = `${process.env.PUBLIC_URL}/PAGAMENTO/DOCUMENTO`;
const DOCUMENTO_UPDATE_STORE = `${process.env.PUBLIC_URL}/PAGAMENTO/DOCUMENTO_UPDATE_STORE`;
const DOCUMENTO_ANEXAR = `${process.env.PUBLIC_URL}/PAGAMENTO/DOCUMENTO_ANEXAR`;
const DOCUMENTO_ANEXAR_PROGRESSO = `${process.env.PUBLIC_URL}PAGAMENTO/DOCUMENTO_ANEXAR_PROGRESSO`;
const CONFIRMACAO_LEITURA = `${process.env.PUBLIC_URL}/PAGAMENTO/CONFIRMACAO_LEITURA`;

export const types = {
  PAGAMENTO_FIND,
  PAGAMENTO_FIND_RESET,
  PAGAMENTO_UPDATE_STORE,
  PAGAMENTO_LOAD_MORE,
  PAGAMENTO_LOAD_MORE_UPDATE_STORE,
  DADOS_PAGAMENTO,
  DADOS_UPDATE_STORE,
  FINALIZAR,
  FINALIZAR_UPDATE_STORE,
  ATRIBUIR,
  ATRIBUIR_UPDATE_STORE,
  DOCUMENTO,
  DOCUMENTO_UPDATE_STORE,
  DOCUMENTO_ANEXAR,
  DOCUMENTO_ANEXAR_PROGRESSO,
  CONFIRMACAO_LEITURA,
};

// ACTIONS
const findPagamentos = createAction(PAGAMENTO_FIND);
const findPagamentosReset = createAction(PAGAMENTO_FIND_RESET);
const updatePagamentos = createAction(PAGAMENTO_UPDATE_STORE);
const loadMore = createAction(PAGAMENTO_LOAD_MORE);
const updateLoadMore = createAction(PAGAMENTO_LOAD_MORE_UPDATE_STORE);
const buscarPagamento = createAction(DADOS_PAGAMENTO);
const updateBuscarPagamento = createAction(DADOS_UPDATE_STORE);
const finalizarPagamento = createAction(FINALIZAR);
const updateFinalizarPagamento = createAction(FINALIZAR_UPDATE_STORE);
const atribuirPagamento = createAction(ATRIBUIR);
const updateAtribuirPagamento = createAction(ATRIBUIR_UPDATE_STORE);
const buscarUrlDocumento = createAction(DOCUMENTO);
const updateBuscarUrlDocumento = createAction(DOCUMENTO_UPDATE_STORE);
const anexarDocumento = createAction(DOCUMENTO_ANEXAR);
const confirmarLeitura = createAction(CONFIRMACAO_LEITURA);
const progressoAnexarDocumento = createAction(DOCUMENTO_ANEXAR_PROGRESSO);

export const actions = {
  findPagamentos,
  findPagamentosReset,
  updatePagamentos,
  loadMore,
  updateLoadMore,
  buscarPagamento,
  updateBuscarPagamento,
  finalizarPagamento,
  updateFinalizarPagamento,
  atribuirPagamento,
  updateAtribuirPagamento,
  buscarUrlDocumento,
  updateBuscarUrlDocumento,
  confirmarLeitura,
  progressoAnexarDocumento,
  anexarDocumento,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [PAGAMENTO_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [PAGAMENTO_FIND_RESET]: () => {
      return {
        state: {},
      };
    },
    [PAGAMENTO_LOAD_MORE_UPDATE_STORE]: (state, action) => {
      const tarefas = state.tarefas.concat(action.payload.tarefas);
      return {
        ...state,
        tarefas: tarefas,
      };
    },
    [DADOS_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        pagamento: action.payload,
        dadosAdicionais: JSON.parse(action.payload.dadosAdicionais),
        urlBaseGdfront: action.payload.urlBaseGdfront,
        authorization: action.payload.authorization,
      };
    },
    [FINALIZAR_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        gdoc: action.payload.gdoc,
      };
    },
    [ATRIBUIR_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
      };
    },
    [DOCUMENTO_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        urlDocumento: action.payload.urlDocumento,
      };
    },
    [DOCUMENTO_ANEXAR_PROGRESSO]: (state, action) => {
      return {
        ...state,
        progress: action.payload.progress,
      };
    },
    [DOCUMENTO_ANEXAR]: (state, action) => {
      return {
        ...state,
        uploadFileNotAuthorized: true,
      };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const getPagamentos = (state) => filter(state);
const getQtdTotal = (state) => getTotal(state);
const getData = (state) => getFinalizacao(state);
const getDadosAdicionaisPagamento = (state) => dadosAdicionaisPagamento(state);
const getUsuarioAtribuido = (state) => usuarioAtribuido(state);
const getTarefa = (state, payload) => getDadosTarefa(state, payload);
const getUrlDocumento = (state) => urlDocumento(state);
const getUrlBaseGdfront = (state) => urlBaseGdfront(state);
const getAuthorization = (state) => authorization(state);
const getProgressoAnexarDocumento = (state) => obterProgressoAnexarDocumento(state);
const getProgressoAnexar = (state) => obterProgressoAnexar(state);

const filter = (state) => {
  return _.values(state.pagamentos.tarefas);
};

const getTotal = (state) => {
  return state.pagamentos.quantidadeTotal;
};

const getFinalizacao = (state) => {
  return state.pagamentos.gdoc;
};

const dadosAdicionaisPagamento = (state) => {
  return state.pagamentos.dadosAdicionais;
};

const usuarioAtribuido = (state) => {
  return state.pagamentos.usuarioAtribuido;
};

const getDadosTarefa = (state, payload) => {
  if (
    state.pagamentos.tarefas === undefined ||
    state.pagamentos.tarefas === null
  ) {
    return;
  }
  return state.pagamentos.tarefas.find((p) => p.cdTarefa === payload);
};

const obterProgressoAnexarDocumento = (state) => {
  if (state.pagamentos.progress === undefined) {
    return 0;
  }
  return state.pagamentos.progress;
};

const obterProgressoAnexar = (state) => {
  // PAGAMENTO/DOCUMENTO_ANEXAR
  const statesUpload = state.flow ? state.flow[DOCUMENTO_ANEXAR] : {};

  state.uploadFileNotAuthorized = false;
  if(statesUpload && statesUpload.done && statesUpload.erro_mensagem){
    state.uploadFileNotAuthorized = (!statesUpload.loading && statesUpload.erro_mensagem.includes('400'));
  }
  return state.uploadFileNotAuthorized;
};

const urlDocumento = (state) => {
  return state.pagamentos.urlDocumento;
};

const urlBaseGdfront = (state) => {
  return state.pagamentos.urlBaseGdfront;
};

const authorization = (state) => {
  return state.pagamentos.authorization;
};

export const selectors = {
  getPagamentos,
  getQtdTotal,
  getData,
  getDadosAdicionaisPagamento,
  getUsuarioAtribuido,
  getTarefa,
  getUrlDocumento,
  getUrlBaseGdfront,
  getAuthorization,
  getProgressoAnexarDocumento,
  getProgressoAnexar
};
