import { all, call, put, takeEvery } from "redux-saga/effects";
import enderecoService from "../../commons/services/endereco";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/endereco";

export default function* enderecoSaga() {
  yield takeEvery(types.ADDRESS_SEARCH, searchAddress);
}

function* searchAddress(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => enderecoService.searchAddress(action.payload)),
      ]);

      yield put(actions.updateAddressStore(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
      yield put(actions.updateAddressStore({}));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },
  });
}
