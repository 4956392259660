import React, { PureComponent } from "react";

export class ReCaptchaComponent extends PureComponent{

    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
    }

    resetReCaptcha = () => {
        if (!this.props.captchaEnabled) {
          return;
        }
        this.recaptchaRef.current.reset();
      };

    validateReCaptcha = () => {
      if (!this.props.captchaEnabled) {
        return;
      }
      this.recaptchaRef.current.execute();
    };

    getReCaptcha = () => {
        if (!this.props.captchaEnabled) {
          return;
        }
        return this.recaptchaRef.current.getValue();
    };
}
