import axios from "axios";

import {
  actions as usuarioActions,
  selectors as usuarioSelectors,
} from "../../redux/modules/usuario";

import { ROUTES } from "../../commons/routes/routes";

axios.defaults.baseURL = `/externo-portal-backend/`;

export default axios;

export const registerAxiosInterceptors = (store, history) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers = config.headers || {};
      const token = usuarioSelectors.getAccessToken(store.getState());

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    // success
    (response) => response,

    // error
    (error) => {

      const errorHasResponse = Boolean(error && error.response);

      if (errorHasResponse && error.response.status === 401) {
        // token já não está mais autorizado
        if ("/logout" !== error.response.config.url) {
          store.dispatch(usuarioActions.forceLogoutUser());
        }
      } else if (errorHasResponse && error.response.status === 429) {
        history.push(ROUTES.STATUS_429.path)
      }

      return Promise.reject(error);
    },
  );
};
