import axios from "../http/axios";

export default {
  findProcesso: (payload) => {
    return axios.post(`/v1/solicitacao/consultar`, payload).then((res) => {
      return res.data;
    });
  },
  findProcessoLinkExterno: (payload) => {
    return axios
      .post(`/v1/solicitacao/consultarLinkExterno`, payload)
      .then((res) => {
        return res.data;
      });
  },
  findProcessoDados: (payload) => {
    return axios
      .post(`/v1/solicitacao/consultarfiltro`, payload)
      .then((res) => {
        return res.data;
      });
  },
  findMaterializacaoUrl: (payload) => {
    return axios
      .post(`/v1/solicitacao/consultarMaterializacao`, payload)
      .then((res) => {
        return res.data;
      });
  },
  consultarDocumento: (payload) => {
    return axios.post(`/v1/documentos/conferir`, payload).then((res) => {
      return res.data;
    });
  },
};
