import React, { PureComponent } from "react";
import AppLoading from "../../App/AppLayout/containers/AppLoading";

class Loading extends PureComponent {
  render() {
    return <AppLoading show={true} transparent={true} />;
  }
}

export default Loading;
