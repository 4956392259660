import React, { Component } from "react";
import withSizes from "react-sizes";
import PropTypes from "prop-types";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import Tidio from "./Tidio"
import Loadable from "react-loadable";
import AppToolbar from "./AppToolbar";
import serviceFormatInput from "../../../services/serviceFormatInput";

const AppMenu = Loadable({
  loader: () => import("./AppMenu"),
  loading: () => null,
});

class LayoutComponent extends Component {
  state = {
    open:
      localStorage.getItem("open") != null
        ? localStorage.getItem("open") === "true"
          ? true
          : false
        : true,
    urlTidio: '',
    chatToken: ''
  };

  componentDidMount() {
    if (this.props.isMobile) {
      localStorage.setItem("open", false);
      this.setState({ open: false });
    }
  }

  setCookie = (variable) => {
    document.cookie = variable+"; path=/";
  };

  handleLogout = () => {
    const payload  = {
      isLoginGov : this.props.isLoginGov,
      isLoginAuth: this.props.isLoginAuth,
      urlGovBr : this.props.urlGovBr,
    }
    this.props.logout(payload, () => {
      if (this.props.isMobile) {
        this.setState({ open: false });
      }
      this.setCookie("SPJSSOID=");
      this.setCookie("JSESSIONID=");
    });
  };

  handleDrawerToggle = () => {
    localStorage.setItem("open", !this.state.open);
    this.setState({ open: !this.state.open });
  };

  closeMenuOnMobile = () => {
    if (this.props.isMobile) {
      localStorage.setItem("open", false);
      this.setState({ open: false });
    }
  };

  renderMenu() {
    const { isAuthenticated, isUserAdmin, isLoginGov, isLoginAuth, isCadIncompleto } = this.props;

    // if (!isAuthenticated) {
    //  return null;
    // }

    return (
      <AppMenu
        open={this.state.open}
        isUserAdmin={isUserAdmin}
        isLoginGov={isLoginGov}
        isLoginAuth={isLoginAuth}
        isCadIncompleto={isCadIncompleto}
        handleLogout={this.handleLogout}
        menuToggle={this.handleDrawerToggle}
        closeMenuOnMobile={this.closeMenuOnMobile}
        isAuthenticated={isAuthenticated}
      />
    );
  }

  callTidioUrlBackend = () => {
    serviceFormatInput
      .get('/configuracoes/frontend/tidio/url')
      .then(({ data }) => {
        if (data) {
          this.setState({ urlTidio: data });
        }
    });
  }

  callChatTokenBackend = () => {
    serviceFormatInput
        .get('/configuracoes/frontend/chat/token')
        .then(({ data }) => {
          if (data) {
            this.setState({ chatToken: data });
          }
        });
  }

  renderTidio = () => {
    if (this.state.urlTidio !== '') {
      return (<Tidio url={this.state.urlTidio} mdChatClient={this.state.chatToken} />);
    } else {
      this.callTidioUrlBackend();
      this.callChatTokenBackend();
    }
  }

  render() {
    const { isAuthenticated, perfils, isCadIncompleto } = this.props;
    const { open } = this.state;

    return (
      <div className="sds-page">
        <AppToolbar
          open={open}
          isAuthenticated={isAuthenticated}
          menuToggle={this.handleDrawerToggle}
          closeMenuOnMobile={this.closeMenuOnMobile}
          perfils={perfils}
          isCadIncompleto={isCadIncompleto}
        />
        <div className="sds-page-wrapper">
          {this.renderMenu()}
          <div className="sds-page-content">{this.props.children}</div>
        </div>
        {this.renderTidio()}
      </div>
    );
  }
}

LayoutComponent.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
  isLoginGov: PropTypes.bool.isRequired,
  isLoginAuth: PropTypes.bool.isRequired,
  urlGovBr: PropTypes.string,
  isCadIncompleto: PropTypes.bool.isRequired,
  token: PropTypes.string,
  logout: PropTypes.func.isRequired,
  perfils: PropTypes.array,
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 992,
});

export default compose(injectIntl)(withSizes(mapSizesToProps)(LayoutComponent));
