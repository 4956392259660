import axios from "../http/axios";

export default {
  carregarConfiguracoesIniciais: () => {
    return axios.get(`/configuracoes/frontend`).then((res) => {
      return res.data;
    });
  },
  carregar: (payload) => {
    return axios
      .get(`/configuracoes?chaves=${payload.chaves}`, {
        headers: { "Cache-Control": "no-cache" },
      })
      .then((res) => {
        return res.data;
      });
  },
  atualizar: (tipo, chave, body, headers) => {
    return axios
      .post(`/configuracoes/${tipo}/${chave}`, body, { headers })
      .then((res) => {
        return res.data;
      });
  },
};
