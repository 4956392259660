import { createMuiTheme } from "@material-ui/core/styles";

const varCss = (color) => getComputedStyle(document.documentElement).getPropertyValue(color).trim();

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  palette: {
    primary: {
      main: varCss("--primary-color"),
    },
    secondary: {
      main: "rgb(0 0 0 / 54%)",
    },
  },
  cards: {
    shadow: "0 5px 10px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%)",
  },
  iconesgpe: {
    width: "24px",
    height: "24px",
    marginRight: "1rem",
  },
});
