import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const ORGAO_SEARCH = `${process.env.PUBLIC_URL}/ORGAO/SEARCH`;
const ORGAO_SEARCH_STORE = `${process.env.PUBLIC_URL}/ORGAO/SEARCH_STORE`;

export const types = {
  ORGAO_SEARCH,
  ORGAO_SEARCH_STORE,
};

// ACTIONS
const searchOrgao = createAction(ORGAO_SEARCH);
const updateOrgao = createAction(ORGAO_SEARCH_STORE);

export const actions = {
  searchOrgao,
  updateOrgao,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [ORGAO_SEARCH_STORE]: (_, action) => ({
      ...action.payload,
    }),
  },
  initialState,
);

export default reducer;

// SELECTORS
const getOrgao = (state) => state.orgao.orgaos;
const getTotalPages = (state) => {
  return state.orgao.total;
};

export const selectors = {
  getOrgao,
  getTotalPages,
};
