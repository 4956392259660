import { all, call, put, takeEvery } from "redux-saga/effects";
import processoService from "../../commons/services/processo";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/processo";

export default function* processoSaga() {
  yield takeEvery(types.PROCESSO_FIND, findProcesso);
  yield takeEvery(types.PROCESSO_FIND_DADOS, findProcessoDados);
  yield takeEvery(types.MATERIALIZACAO_URL, findMaterializacaoUrl);
  yield takeEvery(
    types.PROCESSO_FIND_BY_CHAVE_EXTERNA,
    findProcessoByChaveExterna,
  );
  yield takeEvery(
    types.PROCESSO_FIND_LINK_BY_CHAVE_EXTERNA,
    findProcessoLinkExternoByChaveExterna,
  );
  yield takeEvery(types.PROCESSO_DOC_FIND, consultarDocumento);
}

function* findProcesso(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => processoService.findProcesso(action.payload || [])),
      ]);

      // yield put(actions.updateFiltroProcesso(action.payload))
      yield put(actions.updateProcesso(data));
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* findProcessoDados(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => processoService.findProcessoDados(action.payload || [])),
      ]);

      yield put(actions.updateProcessoDados(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* findMaterializacaoUrl(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => processoService.findMaterializacaoUrl(action.payload || [])),
      ]);

      yield put(actions.updateProcesso(data));
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* findProcessoByChaveExterna(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => processoService.findProcesso(action.payload || [])),
      ]);

      yield put(actions.updateProcesso(data));
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* findProcessoLinkExternoByChaveExterna(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() =>
          processoService.findProcessoLinkExterno(action.payload || []),
        ),
      ]);

      yield put(actions.updateProcesso(data));
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* consultarDocumento(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => processoService.consultarDocumento(action.payload || [])),
      ]);

      yield put(actions.updateDocumento(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}
