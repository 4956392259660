import { addLocaleData } from "react-intl";
import pt from "react-intl/locale-data/pt";

export const DEFAULT_LOCALE = "pt-BR";

export function configureReactIntlPolyfill(renderCallback) {
  if (window.Intl) {
    configureLocale(renderCallback);
    return;
  }

  require(["intl", "intl/locale-data/jsonp/pt.js"], (require) => {
    require("intl");
    require("intl/locale-data/jsonp/pt.js");

    configureLocale(renderCallback);
  });
}

function configureLocale(renderCallback) {
  addLocaleData(pt);
  renderCallback();
}

export function getIntlProviderConfig(msgs) {
  return { locale: getLocale(), messages: msgs || {} };
}

const getLocale = () => {
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    DEFAULT_LOCALE
  );
};
