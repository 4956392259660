/* eslint-disable no-unused-vars */
// TODO: container vs. component
// TODO: analytics
// TODO: login/logout upon expired token
// TODO: favicon
// TODO: centralizar todas as urls externas no backend (ativação, link solicitação, etc)
// TODO: configurar os testes
// TODO: 'redux-devtools-extension/logOnlyInProduction'
// TODO: "redux-persist": "^5.10.0", -> remover store.subscribe/saveStateToLocalStorage & loadStateFromLocalStorage
// TODO: https://developers.google.com/web/fundamentals/web-app-manifest/?hl=en

import _ from "lodash";
import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { IntlProvider } from "react-intl";
import { getIntlProviderConfig } from "../commons/i18n/intl";
import { compose } from "redux";
import { connect } from "react-redux";
import Loadable from "react-loadable";
import ReactGA from "react-ga";

import {
  types as cfgTypes,
  consts as cfgConsts,
  selectors as cfgSelectors,
  actions as cfgActions,
} from "../redux/modules/configuracoes";
import {
  actions as flowActions,
  selectors as flowSelectors,
} from "../redux/modules/flow";
import {
  types as userTypes,
  actions as userActions,
  selectors as userSelectors,
} from "../redux/modules/usuario";

import PrivateRoute from "../commons/containers/PrivateRoute";
import AppModal from "../commons/containers/Modal";
import AppLayout from "./AppLayout/AppLayout";
import { ROUTES } from "../commons/routes/routes";
import Loading from "../commons/containers/Loading";
import AppToolbar from "../App/AppLayout/containers/AppToolbar";
import ProcessoGVE from "../App/Visitante/ConsultaProcessoGVE/ProcessoGVE";
import { axeAccessibilityReporter } from "../utils/a11y/axeAccessibilityReporter";
import { Typography } from "@material-ui/core";

import { datadogRum } from "@datadog/browser-rum";

const Manutencao = Loadable({
  loader: () => import("../commons/containers/Manutencao"),
  loading: Loading,
});

const Sucesso = Loadable({
  loader: () => import("../commons/containers/Sucesso"),
  loading: Loading,
});

const ConsultaProcessoGVE = Loadable({
  loader: () =>
    import("../App/Visitante/ConsultaProcessoGVE/ConsultaProcessoGVE"),
  loading: Loading,
});

class App extends Component {
  constructor(props) {
    super(props);

    this.initialize = _.once(function () {
      const { history, analytics, uxMonitoring } = this.props;

      if (analytics) {
        // inicializando a google analytics
        ReactGA.initialize(analytics);

        // page view da url atual
        ReactGA.pageview(history.location.pathname);

        // ouvindo alterações de url
        history.listen((location) => {
          ReactGA.pageview(location.pathname);
        });
      }

      if (uxMonitoring && uxMonitoring.active === 'true') {
        const ddParans = {
          applicationId: uxMonitoring.applicationId,
          clientToken: uxMonitoring.client_token,
          site: uxMonitoring.site,
          service: uxMonitoring.service,
          env: uxMonitoring.env, // utilizado para criar variável por ambiente. Ex: des, prod
          // Especifique um número de versão para identificar a versão implantada de seu aplicativo no Datadog
          // version: '1.0.0',
          sessionSampleRate: 100,
          sessionReplaySampleRate: 0,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: "mask",
          allowedTracingUrls: [
            uxMonitoring.tracing_url,
            new RegExp(uxMonitoring.tracing_url_regex),
            (url) => url.startsWith(uxMonitoring.tracing_url)
          ],
        }
        datadogRum.init(ddParans);
      }
    });
  }

  findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  shouldLogin() {
    const code = this.findGetParameter("token");
    if (code !== undefined && code !== null && code !== "") {
      return true;
    }

    return false;
    // return !this.props.isAuthenticated && this.state.access_token;
  }

  componentWillUnmount() {
    if (this.shouldLogin()) {
      this.props.reset(userTypes.USER_LOGIN);
    }

    this.props.reset(cfgTypes.FIND);
  }

  componentDidMount() {
    if (this.shouldLogin()) {
      const token = this.findGetParameter("token");
      this.props.forceLogin({ token: token })
    }

    this.props.carregarConfiguracoesIniciais();

    axeAccessibilityReporter();
  }

  renderRoutes(stantalone) {
    return _.chain(ROUTES)
      .map((route) => route)
      .filter((route) => Boolean(route.isStandalone) === stantalone)
      .map((route, i) => {
        return route.isPrivate ? (
          <PrivateRoute key={i} {...route} />
        ) : (
          <Route key={i} {...route} />
        );
      })
      .value();
  }

  verifyIfScriptIsLoaded(scriptName) {
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('id') === scriptName) {
        return true;
      }
    }
    return false;
  }

  addGA4Scripts(analyticsKey) {
    const ga4FirstScript = document.createElement('script');
    ga4FirstScript.id = 'ga4-first-script';
    ga4FirstScript.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsKey}`;
    document.body.appendChild(ga4FirstScript);

    const ga4SecondScript = document.createElement('script');
    ga4SecondScript.id = 'ga4-second-script';
    ga4SecondScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${analyticsKey}');`;
    document.body.appendChild(ga4SecondScript);
  }

  render() {
    const { doneCfgs, loadingCfgs, sucessoCfgs, messages, history, doingLogout, doneLogin, npsKey, analytics } =
      this.props;

    if (analytics) {
      if (!this.verifyIfScriptIsLoaded('ga4-first-script')) {
        this.addGA4Scripts(analytics);
      }
    }

    if (this.props.isAuthenticated) {
      if (npsKey) {
        if (!this.verifyIfScriptIsLoaded('pesquisa-satisfacao-script')) {
          const npsScript = document.createElement('script');
          npsScript.id = 'pesquisa-satisfacao-script';
          npsScript.innerHTML = `(
          function (f, b, g) {
              var a = {
                  key: '${npsKey}',
                  customer: {
                      name: '${this.props.loggedUser.perfils[0].nome}',
                      email: '${this.props.loggedUser.perfils[0].email}',
                      phone: '',
                      Identificador: '${this.props.loggedUser.perfils[0].id}'
                  }
              };
              var e;
              var c = f.getElementsByTagName(b)[0];
              if (f.getElementById(g)) { return };
              e = f.createElement(b);
              e.id = g;
              e.src = 'https://app.track.co/widget.min.js';
              e.type = 'text/javascript';
              e.async = true;
              e.onload = e.onreadystatechange = function () {
                  var h = this.readyState;
                  if (h && h !== 'complete' && h !== 'loaded') { return };
                  try {
                      var c = new TrackWidget();
                      c.createWidget(a);
                  } catch (i) { }
              };
          c.parentNode.insertBefore(e, c);
          }
          (document, 'script', 'trackwidget-js')
          )`;

          document.body.appendChild(npsScript);
        }
      }
    }

    if (
      loadingCfgs ||
      !doneCfgs ||
      doingLogout ||
      (this.shouldLogin() && !doneLogin)
    ) {
      // configurações e login ainda não carregaram...
      return <Loading />;
    }

    if (!sucessoCfgs) {
      return <Manutencao />;
    }

    if (history.location.pathname.indexOf("/sucesso") !== -1) {
      return <Sucesso />;
    }

    if (
      history.location.pathname.indexOf("/oauth2/redirect") !== -1 &&
      history.location.search.indexOf("error=[access_denied]") !== -1
    ) {
      window.location.href = "/login?code=access_denied";
    }

    this.initialize();
    const intlProviderProps = getIntlProviderConfig(messages);

    if (history.location.pathname.indexOf("/processos/v1/") !== -1) {
      return (
        <IntlProvider {...intlProviderProps}>
          <ConnectedRouter history={history}>
            <Fragment>
              <Route component={AppModal} />
              <Switch>
                <Switch>
                  <div className="sds-page">
                    <AppToolbar
                      open={false}
                      renderMenuBool={false} uxMonitoring
                      isAuthenticated={false}
                      menuToggle={() => { }}
                      closeMenuOnMobile={() => { }}
                    />
                    <div className="sds-page-wrapper">
                      <div className="sds-page-content">
                        <ConsultaProcessoGVE
                          pathname={history.location.pathname}
                        />
                      </div>
                    </div>
                  </div>
                </Switch>
              </Switch>
            </Fragment>
          </ConnectedRouter>
        </IntlProvider>
      );
    }

    if (history.location.pathname.indexOf("/processo/v1/id/") !== -1) {
      return (
        <IntlProvider {...intlProviderProps}>
          <ConnectedRouter history={history}>
            <Fragment>
              <Route component={AppModal} />
              <Switch>
                <Switch>
                  <div className="sds-page">
                    <AppToolbar
                      open={false}
                      renderMenuBool={false}
                      isAuthenticated={false}
                      menuToggle={() => { }}
                      closeMenuOnMobile={() => { }}
                    />
                    <div className="sds-page-wrapper">
                      <div className="sds-page-content">
                        <ProcessoGVE
                          chaveExterna={this.props.state.processo.chaveExterna}
                        />
                      </div>
                    </div>
                  </div>
                </Switch>
              </Switch>
            </Fragment>
          </ConnectedRouter>
        </IntlProvider>
      );
    }

    return (
      <IntlProvider {...intlProviderProps}>
        <ConnectedRouter history={history}>
          <Fragment>
            <Route component={AppModal} />
            <Switch>
              {this.renderRoutes(true)}
              <Switch>
                <AppLayout>
                  <main id="main-content" className="main-content-container">
                    <Switch>{this.renderRoutes(false)}</Switch>
                    <Typography id="feedback" variant="srOnly">
                      <span id="mensagem"></span>
                    </Typography>
                  </main>
                </AppLayout>
              </Switch>
            </Switch>
          </Fragment>
        </ConnectedRouter>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingCfgs: flowSelectors.isLoadingByType(
      state,
      cfgTypes.CARREGAR_CONFIGURACOES_INICIAIS,
    ),
    doneCfgs: flowSelectors.isDoneByType(
      state,
      cfgTypes.CARREGAR_CONFIGURACOES_INICIAIS,
    ),
    erroCfgs: flowSelectors.getErrorByType(
      state,
      cfgTypes.CARREGAR_CONFIGURACOES_INICIAIS,
    ),
    sucessoCfgs: flowSelectors.isSuccessByType(
      state,
      cfgTypes.CARREGAR_CONFIGURACOES_INICIAIS,
    ),
    doneLogin: flowSelectors.isDoneByType(state, userTypes.USER_LOGIN_GOVBR),
    doingLogout: flowSelectors.isLoadingByType(state, userTypes.USER_LOGOUT),
    isAuthenticated: userSelectors.isAuthenticated(state),
    loggedUser: userSelectors.getUser(state),
    messages: {
      ...cfgSelectors.getValorConfiguracao(state, cfgConsts.APPLICATION_FRONTEND_RESOURCES),
      ...cfgSelectors.getValorConfiguracao(state, cfgConsts.APPLICATION_STATIC_STRINGS),
    },
    analytics: cfgSelectors.getValorConfiguracao(
      state,
      cfgConsts.GOOGLE_ANALYTICS_KEY,
    ),
    uxMonitoring: cfgSelectors.getValorConfiguracao(
      state,
      cfgConsts.UX_MONITORING_DD,
    ),
    npsKey: cfgSelectors.getValorConfiguracao(
      state,
      cfgConsts.NPS_KEY_ATENDIMENTO,
    ),
    state: state,
  };
}

const mapDispatchToProps = {
  carregarConfiguracoesIniciais: cfgActions.carregarConfiguracoesIniciais,
  reset: flowActions.reset,
  forceLogin: userActions.forceLoginUser,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
