/* eslint-disable no-unused-vars */
import _ from "lodash";
import moment from "moment";
import { select, takeEvery, put, call, all } from "redux-saga/effects";
import { delay } from "redux-saga";

import { validateCpf, formatCpf } from "../../commons/validation/validation";
import SoftplanWebSigner from "../../commons/websigner/softplan-websigner";
import { LACUNA_LICENSE } from "../../commons/websigner/softplan-websigner-license";

import { flow, actions as flowActions } from "../modules/flow";
import { types, actions, consts, selectors } from "../modules/assinatura";
import gdocService from "../../commons/services/gdoc";

export default function* solicitacoesSaga() {
  yield takeEvery(types.INIT, init);
  yield takeEvery(types.CARREGAR_CERTIFICADOS, carregarCertificados);
  yield takeEvery(types.ASSINAR, assinar);
}

function* init(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const signer = new SoftplanWebSigner(LACUNA_LICENSE);
      let initResult = {
        installation_status: consts.SIGNER_INSTALLATION_STATE_ERROR,
      };

      const initConfig = {
        ready: function () {
          initResult = {
            installation_status: consts.SIGNER_INSTALLATION_STATE_INSTALLED,
          };
        },
        notInstalled: function (status, message) {
          initResult = {
            installation_status: status,
            installation_status_messsage: message,
          };
        },
        defaultError: function (message, error, origin) {
          initResult = {
            installation_status: consts.SIGNER_INSTALLATION_STATE_ERROR,
            installation_status_messsage: message,
          };
        },
      };

      yield call(signer.init(initConfig).getRealPromise);

      yield put(actions.updateStore({ ...initResult, signer }));
    },
  });
}

function* carregarCertificados(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const signer = yield select(selectors.getSigner);

      let [certificados] = yield all([
        call(signer.listCertificates().getRealPromise),
        call(delay, 500), // adicionando delay para feedback do usuário
      ]);

      // extraindo do objeto somente as informações necessárias
      // convertendo de array para mapa (object)
      certificados = _.keyBy(
        _.map(certificados, (c) => {
          // recuperando somente as informações necessárias para a assinatura
          return {
            thumb: c.thumbprint,
            cpf: extrairCpfDoCertificado(c),
            nome: c.subjectName,
            validade:
              c.validityEnd &&
              moment(c.validityEnd).format("DD/MM/YYYY HH:mm:ss"),
          };
        }),
        "thumb",
      );

      yield put(actions.updateStore({ certificados }));
    },
  });
}

function* assinar(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const { type, payload } = action;
      const { thumb, codigos, callback } = payload;

      // validação dos campos obrigatórios da requisição
      if (_.isEmpty(thumb) || _.isEmpty(codigos)) {
        throw new Error(
          "LABELS.COMMONS.ASSINATURA.VALIDACAO_PARAMETROS_INVALIDOS",
        );
      }

      const signer = yield select(selectors.getSigner);

      // configurando as tarefas
      const tarefas = [
        "LABELS.COMMONS.ASSINATURA.INICIAR_ASSINATURA",
        "LABELS.COMMONS.ASSINATURA.REALIZAR_ASSINATURA",
        "LABELS.COMMONS.ASSINATURA.FINALIZAR_ASSINATURA",
      ];
      yield put(flowActions.progress({ type, progress: { tarefas } }));

      // iniciando a assinatura
      yield put(flowActions.progress({ type }));
      const { certBinaryEncoded, hashes, date } = yield call(
        iniciarAssinatura,
        signer,
        thumb,
        codigos,
      );

      // assinando
      yield put(flowActions.progress({ type }));
      const signatures = yield call(realizarAssinatura, signer, thumb, hashes);

      // finalizando a assinatura
      yield put(flowActions.progress({ type }));
      yield call(finalizarAssinatura, certBinaryEncoded, signatures, date);

      // exibir feedback para o usuário
      yield put(flowActions.progress({ type }));
      yield call(delay, 500);

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },
  });
}

function* iniciarAssinatura(signer, thumb, codigos) {
  try {
    const certBinaryEncoded = yield call(
      signer.readCertificate(thumb).getRealPromise,
    );

    const req = {
      ids: codigos,
      certificate: certBinaryEncoded,
    };

    const { hashes } = yield call(gdocService.start, req);

    const date = _.first(_.map(hashes, (hash) => hash.data));

    return { certBinaryEncoded, hashes, date };
  } catch (e) {
    console.error(e);
    throw new Error("LABELS.COMMONS.ASSINATURA.ERRO_INICIAR_ASSINATURA");
  }
}

function* realizarAssinatura(signer, thumb, hashes) {
  try {
    const preauthorizeArgs = {
      certificateThumbprint: thumb,
      signatureCount: _.size(hashes),
    };
    yield call(signer.preauthorizeSignatures(preauthorizeArgs).getRealPromise);

    const effects = [];

    _.each(hashes, (hash, id) => {
      const req = {
        thumbprint: thumb,
        data: hash.hash,
        digestAlgorithm: "SHA-256",
      };

      effects.push(
        call(function* () {
          const hash = yield call(signer.signData(req).getRealPromise);
          return { id, hash };
        }),
      );
    });

    return yield all(effects);
  } catch (e) {
    console.error(e);
    throw new Error("LABELS.COMMONS.ASSINATURA.ERRO_REALIZAR_ASSINATURA");
  }
}

function* finalizarAssinatura(certBinaryEncoded, signatures, date) {
  try {
    const req = {
      certificate: certBinaryEncoded,
      data: date,
      signatures: signatures,
    };

    return yield call(gdocService.complete, req);
  } catch (e) {
    console.error(e);
    throw new Error("LABELS.COMMONS.ASSINATURA.ERRO_FINALIZAR_ASSINATURA");
  }
}

function extrairCpfDoCertificado(certificate) {
  if (!certificate) {
    return undefined;
  }

  const brasil = certificate.pkiBrazil;
  if (!brasil) {
    return undefined;
  }

  if (!brasil.isPessoaFisica) {
    return undefined;
  }

  const cpf = brasil.cpf;
  if (!cpf) {
    return undefined;
  }

  const result = validateCpf(cpf);
  if (result) {
    return undefined;
  }

  return formatCpf(cpf);
}
