import axios from "../http/axios";

export default {
  findAll: () => {
    return axios
      .get(`/v1/areas-interesses`, { headers: { "Cache-Control": "no-cache" } })
      .then((res) => {
        return res.data;
      });
  },
  findServicos: (payload) => {
    return axios
      .get(`/v1/areas-interesses/${payload.id}?nuPagina=${payload.nuPagina}`, {
        headers: { "Cache-Control": "no-cache" },
      })
      .then((res) => {
        return res.data;
      });
  },
};
