import { combineReducers } from "redux";
import { reducer as FormReducer } from "redux-form";

import ModalReducer from "./modules/modal";
import UsuarioReducer from "./modules/usuario";
import VisitanteReducer from "./modules/visitante";
import SolicitacoesReducer from "./modules/solicitacoes";
import FlowReducer from "./modules/flow";
import AssinaturaReducer from "./modules/assinatura";
import ConfiguracoesReducer from "./modules/configuracoes";
import AreasInteressesReducer from "./modules/areasInteresses";
import ServicosReducer from "./modules/servicos";
import EnderecoReducer from "./modules/endereco";
import FileReducer from "./modules/file";
import PendenciasReducer from "./modules/pendencias";
import PagamentosReducer from "./modules/pagamentos";
import AssinaturasPendentesReducer from "./modules/assinaturasPendentes";
import OrgaoReducer from "./modules/orgao";
import ProcessoReducer from "./modules/processo";
import InteressadoReducer from "./modules/interessado";
import AssuntoReducer from "./modules/assunto";
import ClasseReducer from "./modules/classe";

const rootReducer = combineReducers({
  form: FormReducer,
  modal: ModalReducer,
  usuario: UsuarioReducer,
  visitante: VisitanteReducer,
  flow: FlowReducer,
  solicitacoes: SolicitacoesReducer,
  assinatura: AssinaturaReducer,
  configuracoes: ConfiguracoesReducer,
  areasInteresses: AreasInteressesReducer,
  servicos: ServicosReducer,
  endereco: EnderecoReducer,
  file: FileReducer,
  pendencias: PendenciasReducer,
  pagamentos: PagamentosReducer,
  orgao: OrgaoReducer,
  processo: ProcessoReducer,
  interessado: InteressadoReducer,
  assunto: AssuntoReducer,
  classe: ClasseReducer,
  assinaturasPendentes: AssinaturasPendentesReducer,
});

export default rootReducer;
