import { all } from "redux-saga/effects";

import usuarioSaga from "./saga_usuario";
import visitanteSaga from "./saga_visitante";
import solicitacoesSaga from "./saga_solicitacoes";
import assinaturaSaga from "./saga_assinatura";
import configuracoesSaga from "./saga_configuracoes";
import areasInteressesSaga from "./saga_areas_interesses";
import servicosSaga from "./saga_servicos";
import enderecoSaga from "./saga_endereco";
import fileSaga from "./saga_file";
import pendenciasSaga from "./saga_pendencias";
import pagamentosSaga from "./saga_pagamentos";
import orgaoSaga from "./saga_orgao";
import interessadoSaga from "./saga_interessado";
import processoSaga from "./saga_processo";
import assuntoSaga from "./saga_assunto";
import classeSaga from "./saga_classe";
import assinaturasPendentesSaga from './saga_assinaturas_pendentes';

export default function* watchMany() {
  yield all([
    visitanteSaga(),
    usuarioSaga(),
    solicitacoesSaga(),
    assinaturaSaga(),
    configuracoesSaga(),
    areasInteressesSaga(),
    servicosSaga(),
    enderecoSaga(),
    fileSaga(),
    pendenciasSaga(),
    pagamentosSaga(),
    orgaoSaga(),
    processoSaga(),
    interessadoSaga(),
    assuntoSaga(),
    classeSaga(),
    assinaturasPendentesSaga(),
  ]);
}
