import { all, call, put, takeEvery } from "redux-saga/effects";
import areasInteressesService from "../../commons/services/areas_interesses";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/areasInteresses";

export default function* areasInteressesSaga() {
  yield takeEvery(types.AREASINTERESSES_FIND_ALL, findAll);
  yield takeEvery(
    types.SERVICOS_POR_AREAINTERESSE_FIND,
    findServicosPorAreaInteresse,
  );
}

function* findAll(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => areasInteressesService.findAll(action.payload || [])),
      ]);

      yield put(actions.updateAllAreasInteressesStore(data));
    },
  });
}

function* findServicosPorAreaInteresse(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => areasInteressesService.findServicos(action.payload)),
      ]);

      yield put(actions.updateServicosPorAreaInteresseStore(data));
    },
  });
}
