import { push, replace } from "react-router-redux";
import { all, takeEvery, put, call, select } from "redux-saga/effects";
import { flow } from "../modules/flow";
import { delay } from "redux-saga";
import { Base64 } from "js-base64";

import { types, actions } from "../modules/usuario";
import usuarioService from "../../commons/services/usuario";
import { ROUTES } from "../../commons/routes/routes";
import {setCookieWithParseStr} from "../../commons/services/setCookie";

export default function* loginSaga() {
  yield takeEvery(types.USER_LOGIN, login);
  yield takeEvery(types.USER_LOGIN_GOVBR, loginGovBr);
  yield takeEvery(types.USER_LOGOUT, logout);
  yield takeEvery(types.USER_SIMPLE_LOGOUT, logoutSimples);
  yield takeEvery(types.USER_PASSWORD_CHANGE, changePassword);
  yield takeEvery(types.USER_FIND, find);
  yield takeEvery(types.USER_CHANGE, change);
  yield takeEvery(
    types.USER_DADOS_EDITAR_CADASTRO,
    buscarDadosParaEditarCadastro,
  );
  yield takeEvery(types.USER_EDITAR_CADASTRO, editarCadastro);
}

function* change(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const perfilId = action.payload.perfilId;
      const perfilsActual = action.payload.perfils;

      var perfils = [];
      let sessionIdEncoded = '';
      let flCadCompletoGovBr;
      if (perfilsActual && perfilsActual.length > 0) {
        for (let index = 0; index < perfilsActual.length; index++) {
          const perfil = perfilsActual[index];
          let tokenPerfil = perfil.token || '';
          let sessionIdPerfil = perfil.sessionId || '';
          let ativo = false;

          if (perfil.id === perfilId) {
            ativo = true;
            flCadCompletoGovBr = perfil.flCadCompletoGovBr;
            if(tokenPerfil === ''){
              const payload = {
                cdUsuarioAlvo: perfil.id,
              };

              const [tokensEmpresa] = yield all([call(() => usuarioService.alterarUsuario(payload))]);

              tokenPerfil = tokensEmpresa.token || '';
              sessionIdPerfil = tokensEmpresa.sessionId || '';
              sessionIdEncoded = sessionIdPerfil;

              if(tokenPerfil === ''){
                perfils[0].active = true;
                sessionIdEncoded = perfils[0].sessionId;
                ativo = false;
              }
            } else {
              sessionIdEncoded = perfil.sessionId
            }

          }
          perfils[index] = {
            id: perfil.id,
            identificacao: perfil.identificacao,
            nome: perfil.nome,
            tp_identificacao: perfil.tp_identificacao,
            flCadCompletoGovBr: perfil.flCadCompletoGovBr,
            email: perfil.email,
            token: tokenPerfil,
            token_type: perfil.token_type,
            sessionId: sessionIdPerfil,
            refresh_token: perfil.refresh_token,
            active: ativo,
          };
        }
      } else {
        perfils[0] = {
          id: perfilsActual.id,
          identificacao: perfilsActual.identificacao,
          nome: perfilsActual.nome,
          tp_identificacao: perfilsActual.tp_identificacao,
          flCadCompletoGovBr: perfilsActual.flCadCompletoGovBr,
          email: perfilsActual.email,
          token: perfilsActual.token,
          token_type: perfilsActual.token_type,
          sessionId: perfilsActual.sessionId,
          refresh_token: perfilsActual.refresh_token,
          active: true,
        };
      }

      yield put(actions.updateUserStore({ perfils: perfils }));

      const sessionIdDecoded = Base64.decode(sessionIdEncoded);
      setCookieWithParseStr(sessionIdDecoded);

      if(flCadCompletoGovBr === 'N'){
        yield put(push(ROUTES.EDITAR_CONTA_DADOS.path));
      }
      yield call(delay, 750); // adicionado um delay de 750ms para exibir feedback para o usuário
    },
  });
}

// Esse effect passou por mudanças, pra comportar apenas login por usuário e senha
function* login(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {

      action.payload.usuario = action.payload.usuario.match(/\d/g).join(""); // remove máscara de CPF/CNPJ. TODO passar esse tratamento para outro lugar

      const [login] = yield all([
        call(() => usuarioService.login(action.payload)),
      ]);

      yield put(
        actions.updateUserStore({
          authenticated: false,
          mensagemErro: login.mensagemErro,
          perfils: [
            {
              active: true,
              token: login.token,
            },
          ],
        }),
      );

      if (login.token !== null && login.token !== "") {


        const [usuario] = yield all([call(() => usuarioService.get())]);

        var perfils = [];
        perfils[0] = {
          id: usuario.identificacao,
          identificacao: usuario.identificacaoFormatado,
          nome: usuario.nome,
          tp_identificacao: usuario.tp_identificacao,
          flCadCompletoGovBr: usuario.flCadCompletoGovBr,
          email: usuario.email,
          token: login.token,
          token_type: login.token_type,
          sessionId: login.sessionId,
          refresh_token: login.refresh_token,
          active: true,
        }

        yield put(
          actions.updateUserStore({
            authenticated: true,
            perfils: perfils,
            authorities: usuario.authorities,
            tpLogin: usuario.tpLogin,
            idNivelContaGovBr: usuario.idNivelContaGovBr,
            empresas: usuario.empresas,
            mensagemErro: login.mensagemErro,
          }),
        );

        yield all([call(() => usuarioService.update())]);

        const sessionIdDecoded = Base64.decode(login.sessionId);
        setCookieWithParseStr(sessionIdDecoded);

        const url = usuario.flCadCompletoGovBr === 'N' ? ROUTES.EDITAR_CONTA_DADOS.path : action.payload.redirectUrl;

        yield put(replace(ROUTES.ONBOARDING.path));
        yield put(push(url));

        }
      },
  });
}

function* loginGovBr(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {

      yield put(
        actions.updateUserStore({
          authenticated: false,
          perfils: [
            {
              active: true,
              token: action.payload.token,
            },
          ],
        }),
      );

      const [login] = yield all([
        call(() => usuarioService.loginGovBr()),
      ]);

      yield put(
        actions.updateUserStore({
          mensagemErro: login.mensagemErro,
          authenticated: false,
          perfils: [
            {
              active: true,
              token: login.token,
            },
          ],
        }),
      );


      if (login.token !== null && login.token !== "") {

        const [usuario] = yield all([call(() => usuarioService.get())]);

        var perfils = [];
        if (usuario.empresas && usuario.empresas.length > 0) {
          perfils = new Array(usuario.empresas.length + 1);
          perfils[0] = {
            id: usuario.identificacao,
            identificacao: usuario.identificacaoFormatado,
            nome: usuario.nome,
            tp_identificacao: usuario.tp_identificacao,
            flCadCompletoGovBr: usuario.flCadCompletoGovBr,
            email: usuario.email,
            token: login.token,
            token_type: login.token_type,
            sessionId: login.sessionId,
            refresh_token: login.refresh_token,
            active: true,
          };
          for (let index = 1; index < usuario.empresas.length + 1; index++) {
            perfils[index] = {
              id: usuario.empresas[index - 1].cnpj,
              identificacao: usuario.empresas[index - 1].identificacaoFormatado,
              nome: usuario.empresas[index - 1].razaoSocial,
              tp_identificacao: usuario.empresas[index - 1].tp_identificacao,
              flCadCompletoGovBr: usuario.empresas[index - 1].flCadCompletoGovBr,
              email: usuario.empresas[index - 1].email,
              token: "",
              token_type: login.token_type,
              sessionId: "",
              refresh_token: "",
              active: false,
            };
          }
        } else {
          perfils[0] = {
            id: usuario.identificacao,
            identificacao: usuario.identificacaoFormatado,
            nome: usuario.nome,
            tp_identificacao: usuario.tp_identificacao,
            flCadCompletoGovBr: usuario.flCadCompletoGovBr,
            email: usuario.email,
            token: login.token,
            token_type: login.token_type,
            sessionId: login.sessionId,
            refresh_token: login.refresh_token,
            active: true,
          };
        }

        yield put(
          actions.updateUserStore({
            authenticated: true,
            perfils: perfils,
            authorities: usuario.authorities,
            tpLogin: usuario.tpLogin,
            idNivelContaGovBr: usuario.idNivelContaGovBr,
            empresas: usuario.empresas,
            mensagemErro: login.mensagemErro,
          }),
        );

        yield all([call(() => usuarioService.updateGov())]);

        const sessionIdDecoded = Base64.decode(login.sessionId);
        setCookieWithParseStr(sessionIdDecoded);

        const url = usuario.flCadCompletoGovBr === 'N' ? ROUTES.EDITAR_CONTA_DADOS.path : ROUTES.MINHAS_SOLICITACOES.path;
        yield put(replace(ROUTES.ONBOARDING.path));
        yield put(push(url));
        }
      },
  });
}

function* logout(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      yield all([call(() => usuarioService.logout())]);

      yield put(actions.removeUserStore({}));
      if (action.meta && action.meta.force === true) {
        yield put(actions.userForceLogoutStore({}));
      }
      // callback
      if (action.payload && typeof action.payload === "function") {
        action.payload();
      }

      if(action.payload && action.payload.isLoginGov){
        // Verifica se o navegador já realizou a limpeza do localStore.
        while(localStorage.getItem("br.com.softplan.ungp.cpa.portal.solicitacoes") !== '{"usuario":{}}'){
          yield call(delay, 100);
        }
        yield call(() => {
          window.location.href =
            `${action.payload.urlGovBr}logout?post_logout_redirect_uri=${window.location.origin}${process.env.PUBLIC_URL}/`;
        });
      } else {
        yield put(push(ROUTES.ONBOARDING.path));
      }
    },
    fnCatch: function* () {
      yield put(actions.removeUserStore({}));
      if (action.meta && action.meta.force === true) {
        yield put(actions.userForceLogoutStore({}));
      }
    },
  });
}

function* logoutSimples(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      yield put(actions.removeUserStore({}));
    },
  });
}

function* changePassword(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      yield all([call(() => usuarioService.alterarSenha(action.payload))]);
    },
  });
}

function* find(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([call(() => usuarioService.get())]);

      yield put(actions.updateUserStore(data));
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* buscarDadosParaEditarCadastro(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => usuarioService.buscarDadosParaEditarCadastro(),
        ),
      ]);

      const { callback } = action.payload;

      if (callback && typeof callback === "function") {
        callback(data);
      }
    },
  });
}

function* editarCadastro(action) {

  yield* flow({
    type: action.type,

    fnTry: function* () {

      const [data] = yield all([
        call(() => usuarioService.editarCadastro(action.payload)),
      ]);

      const perfilsOld = yield select(state => state.usuario.perfils);

      let novoEmail;
      let novoFlCadCompletoGovBr;
      var perfils = [];
      if (perfilsOld && perfilsOld.length > 0) {
        perfils = new Array(perfilsOld.length);
        for (let index = 0; index < perfilsOld.length; index++) {
          const perfil = perfilsOld[index];
          if(perfil.active){
            novoEmail = data.deEmail;
            novoFlCadCompletoGovBr = data.flCadCompletoGovBr;
          } else {
            novoEmail = perfil.email;
            novoFlCadCompletoGovBr = perfil.flCadCompletoGovBr;
          }
          perfils[index] = {
            id: perfil.id,
            identificacao: perfil.identificacao,
            nome: perfil.nome,
            tp_identificacao: perfil.tp_identificacao,
            flCadCompletoGovBr: novoFlCadCompletoGovBr,
            email: novoEmail,
            token: perfil.token,
            token_type: perfil.token_type,
            sessionId: perfil.sessionId,
            refresh_token: perfil.refresh_token,
            active: perfil.active,
          };
        }
      } else {
        perfils[0] = {
          id: perfilsOld.id,
          identificacao: perfilsOld.identificacao,
          nome: perfilsOld.nome,
          tp_identificacao: perfilsOld.tp_identificacao,
          flCadCompletoGovBr: data.flCadCompletoGovBr,
          email: data.deEmail,
          token: perfilsOld.token,
          token_type: perfilsOld.token_type,
          sessionId: perfilsOld.sessionId,
          refresh_token: perfilsOld.refresh_token,
          active: true,
        };
      }

      yield put(actions.updateUserStore({ perfils: perfils }));

      const { callback } = action.payload;

      if (callback && typeof callback === "function") {
        callback(data);
      }
    },
  });
}
