import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import classnames from "classnames";

class SdsInput extends PureComponent {
  state = {
    isTypePassword: this.props.type === "password",
    type: this.props.type,
  };

  handleTogglePasswordText = (e) => {
    e.preventDefault();
    this.setState({
      type: this.state.type === "password" ? "text" : "password",
    });
  };

  render() {
    const { intl, input, meta, hidden, ...passthrough } = this.props;
    const { error, touched } = meta;
    const showError = touched && error;

    return (
      <div
        className={classnames(
          "sds-form-group",
          { "sds-form-group--error": showError },
          { "field-hidden": hidden },
        )}
      >
        <div className="sds-input-wrapper sds-input--floating-label">
          <input
            id={input.name}
            className={classnames("sds-input", {
              "sds-input--filled": input.value,
            })}
            {...input}
            {...passthrough}
            type={this.state.type}
          />
          <label
            className="sds-label"
            htmlFor={input.name}
            // TODO adiciona label ao BD
            aria-label={
              this.props.required
                ? `${intl.formatMessage({
                    id: this.props.label,
                  })} - campo obrigatório`
                : `${intl.formatMessage({
                    id: this.props.label,
                  })}`
            }
          >
            <FormattedMessage id={this.props.label} />
            {this.props.required ? "*" : ""}
          </label>
          {this.state.isTypePassword && (
            <button
              type="button"
              className="sds-input__icon"
              tabIndex="-1"
              onClick={this.handleTogglePasswordText}
            >
              <i className="material-icons">remove_red_eye</i>
            </button>
          )}
        </div>
        {showError && (
          <span className="sds-caption" aria-live="assertive" role="alert">
            <FormattedMessage id={this.props.label} /> -{" "}
            <FormattedMessage id={error} />
          </span>
        )}
      </div>
    );
  }
}

SdsInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default compose(injectIntl)(SdsInput);
