import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const ASSUNTO_SEARCH = `${process.env.PUBLIC_URL}/ASSUNTO/SEARCH`;
const ASSUNTO_SEARCH_STORE = `${process.env.PUBLIC_URL}/ASSUNTO/SEARCH_STORE`;

export const types = {
  ASSUNTO_SEARCH,
  ASSUNTO_SEARCH_STORE,
};

// ACTIONS
const searchAssunto = createAction(ASSUNTO_SEARCH);
const updateAssunto = createAction(ASSUNTO_SEARCH_STORE);

export const actions = {
  searchAssunto,
  updateAssunto,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [ASSUNTO_SEARCH_STORE]: (_, action) => ({
      ...action.payload,
    }),
  },
  initialState,
);

export default reducer;

// SELECTORS
const getAssunto = (state) => state.assunto.itens;
const getAssuntoTotalPages = (state) => {
  return state.assunto.total;
};

export const selectors = {
  getAssunto,
  getAssuntoTotalPages,
};
