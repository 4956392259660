/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./high-contrast-styles.css";

const HighContrast = () => {
  const [contrast, setContrast] = useState(false);

  const handleContrast = () => {
    setContrast((contrast) => !contrast);
    const divRoot = document.getElementById("root");

    contrast
      ? divRoot.classList.remove("high-contrast")
      : divRoot.classList.add("high-contrast");
  };

  return (
    <section aria-label="Alto Contraste">
      <button
        className="high-contrast__content"
        onClick={handleContrast}
        role="switch"
        aria-checked={contrast === true}
        tabIndex={0}
        title="Alto Contraste"
        aria-label="Alto Contraste"
      >
        <i className="material-icons high-contrast__icon" aria-hidden="true">
          contrast
        </i>
        <span className="high-contrast__title">Alto Contraste</span>
      </button>
    </section>
  );
};

export default HighContrast;
