export const setCookie = (variable, value) => {
  document.cookie = variable + "=" + value + "; path=/";
};

/**
 * @param {data} cookieStr = SPJSSOID=CDUSUARIO; JSESSIONID=UUID
 */
export const setCookieWithParseStr = (cookieStr) => {
  let JSESSIONID = "";
  let SPJSSOID = "";
  if(cookieStr && cookieStr.length > 0){
    const sessionArray = cookieStr.split(" ");
    const JSESSIONIDSTR = sessionArray[1].split("JSESSIONID=")
    const SPJSSOIDSTR = sessionArray[0].split("SPJSSOID=")
    JSESSIONID = JSESSIONIDSTR[1];
    SPJSSOID = SPJSSOIDSTR[1];
  }
  setCookie("SPJSSOID", SPJSSOID);
  setCookie("JSESSIONID", JSESSIONID);
};