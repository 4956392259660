import { createAction, handleActions } from "redux-actions";

// DOMAIN SPECIFIC CONSTANTS
// https://docs.lacunasoftware.com/en-us/content/typedocs/web-pki/enums/_lacuna_web_pki_d_.lacunawebpki.installationstates.html
const SIGNER_INSTALLATION_STATE_ERROR = -1;
const SIGNER_INSTALLATION_STATE_INSTALLED = 0;
const SIGNER_INSTALLATION_STATE_NOT_INSTALLED = 1;
const SIGNER_INSTALLATION_STATE_OUTDATED = 2;
const SIGNER_INSTALLATION_STATE_BROWSER_NOT_SUPPORTED = 3;

export const consts = {
  SIGNER_INSTALLATION_STATE_ERROR,
  SIGNER_INSTALLATION_STATE_INSTALLED,
  SIGNER_INSTALLATION_STATE_NOT_INSTALLED,
  SIGNER_INSTALLATION_STATE_OUTDATED,
  SIGNER_INSTALLATION_STATE_BROWSER_NOT_SUPPORTED,
};

// ACTION TYPES
const INIT = `${process.env.PUBLIC_URL}/SIGNATURE/INIT`;
const CARREGAR_CERTIFICADOS = `${process.env.PUBLIC_URL}/SIGNATURE/CARREGAR_CERTIFICADOS`;
const ASSINAR = `${process.env.PUBLIC_URL}/SIGNATURE/ASSINAR`;
const UPDATE_STORE = `${process.env.PUBLIC_URL}/SIGNATURE/UPDATE_STORE`;

export const types = {
  INIT,
  CARREGAR_CERTIFICADOS,
  ASSINAR,
  UPDATE_STORE,
};

// ACTIONS
const init = createAction(INIT);
const carregarCertificados = createAction(CARREGAR_CERTIFICADOS);
const assinar = createAction(ASSINAR);
const updateStore = createAction(UPDATE_STORE);

export const actions = {
  init,
  carregarCertificados,
  assinar,
  updateStore,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [UPDATE_STORE]: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const isSignerInstallationState = (state, status) => {
  return state.assinatura.installation_status === status;
};

const isPluginInstalado = (state) => {
  return isSignerInstallationState(state, SIGNER_INSTALLATION_STATE_INSTALLED);
};

const isPluginNaoInstalado = (state) => {
  return isSignerInstallationState(
    state,
    SIGNER_INSTALLATION_STATE_NOT_INSTALLED,
  );
};

const isPluginDesatualizado = (state) => {
  return isSignerInstallationState(state, SIGNER_INSTALLATION_STATE_OUTDATED);
};

const isPluginBrowserNaoSuportado = (state) => {
  return isSignerInstallationState(
    state,
    SIGNER_INSTALLATION_STATE_BROWSER_NOT_SUPPORTED,
  );
};

const isPluginErroVerificacao = (state) => {
  return isSignerInstallationState(state, SIGNER_INSTALLATION_STATE_ERROR);
};

const getSigner = (state) => {
  return state.assinatura.signer;
};

const getCertificados = (state) => {
  return state.assinatura.certificados || {};
};

const getUrlPaginaInstalacao = (state) => {
  const { signer } = state.assinatura;
  if (!signer) {
    return function () {
      /* vazio */
    };
  }
  return signer.buildUrlToInstallPage();
};

export const selectors = {
  isPluginInstalado,
  isPluginNaoInstalado,
  isPluginDesatualizado,
  isPluginBrowserNaoSuportado,
  isPluginErroVerificacao,
  getSigner,
  getCertificados,
  getUrlPaginaInstalacao,
};
