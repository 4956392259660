import _ from "lodash";

import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const PENDENCIA_FIND = `${process.env.PUBLIC_URL}/PENDENCIA/FIND`;
const PENDENCIA_FIND_RESET = `${process.env.PUBLIC_URL}/PENDENCIA/FIND_RESET`;
const PENDENCIA_UPDATE_STORE = `${process.env.PUBLIC_URL}/PENDENCIA/UPDATE_STORE`;
const PENDENCIA_LOAD_MORE = `${process.env.PUBLIC_URL}/PENDENCIA/LOAD_MORE`;
const PENDENCIA_LOAD_MORE_UPDATE_STORE = `${process.env.PUBLIC_URL}/PENDENCIA/LOAD_MORE_UPDATE_STORE`;
const DADOS_PENDENCIA = `${process.env.PUBLIC_URL}/PENDENCIA/DADOS`;
const DADOS_UPDATE_STORE = `${process.env.PUBLIC_URL}/PENDENCIA/DADOS_UPDATE_STORE`;
const FINALIZAR = `${process.env.PUBLIC_URL}/PENDENCIA/FINALIZAR`;
const FINALIZAR_UPDATE_STORE = `${process.env.PUBLIC_URL}/PENDENCIA/FINALIZAR_UPDATE_STORE`;
const ATRIBUIR = `${process.env.PUBLIC_URL}/PENDENCIA/ATRIBUIR`;
const ATRIBUIR_UPDATE_STORE = `${process.env.PUBLIC_URL}/PENDENCIA/ATRIBUIR_UPDATE_STORE`;
const DOCUMENTO = `${process.env.PUBLIC_URL}/PENDENCIA/DOCUMENTO`;
const DOCUMENTO_UPDATE_STORE = `${process.env.PUBLIC_URL}/PENDENCIA/DOCUMENTO_UPDATE_STORE`;
const DOCUMENTO_ANEXAR = `${process.env.PUBLIC_URL}/PENDENCIA/DOCUMENTO_ANEXAR`;
const DOCUMENTO_ANEXAR_PROGRESSO = `${process.env.PUBLIC_URL}PENDENCIA/DOCUMENTO_ANEXAR_PROGRESSO`;
const CONFIRMACAO_LEITURA = `${process.env.PUBLIC_URL}/PENDENCIA/CONFIRMACAO_LEITURA`;

export const types = {
  PENDENCIA_FIND,
  PENDENCIA_FIND_RESET,
  PENDENCIA_UPDATE_STORE,
  PENDENCIA_LOAD_MORE,
  PENDENCIA_LOAD_MORE_UPDATE_STORE,
  DADOS_PENDENCIA,
  DADOS_UPDATE_STORE,
  FINALIZAR,
  FINALIZAR_UPDATE_STORE,
  ATRIBUIR,
  ATRIBUIR_UPDATE_STORE,
  DOCUMENTO,
  DOCUMENTO_UPDATE_STORE,
  DOCUMENTO_ANEXAR,
  DOCUMENTO_ANEXAR_PROGRESSO,
  CONFIRMACAO_LEITURA,
};

// ACTIONS
const findPendencias = createAction(PENDENCIA_FIND);
const findPendenciasReset = createAction(PENDENCIA_FIND_RESET);
const updatePendencias = createAction(PENDENCIA_UPDATE_STORE);
const loadMore = createAction(PENDENCIA_LOAD_MORE);
const updateLoadMore = createAction(PENDENCIA_LOAD_MORE_UPDATE_STORE);
const buscarPendencia = createAction(DADOS_PENDENCIA);
const updateBuscarPendencia = createAction(DADOS_UPDATE_STORE);
const finalizarPendencia = createAction(FINALIZAR);
const updateFinalizarPendencia = createAction(FINALIZAR_UPDATE_STORE);
const atribuirPendencia = createAction(ATRIBUIR);
const updateAtribuirPendencia = createAction(ATRIBUIR_UPDATE_STORE);
const buscarUrlDocumento = createAction(DOCUMENTO);
const updateBuscarUrlDocumento = createAction(DOCUMENTO_UPDATE_STORE);
const anexarDocumento = createAction(DOCUMENTO_ANEXAR);
const confirmarLeitura = createAction(CONFIRMACAO_LEITURA);
const progressoAnexarDocumento = createAction(DOCUMENTO_ANEXAR_PROGRESSO);

export const actions = {
  findPendencias,
  findPendenciasReset,
  updatePendencias,
  loadMore,
  updateLoadMore,
  buscarPendencia,
  updateBuscarPendencia,
  finalizarPendencia,
  updateFinalizarPendencia,
  atribuirPendencia,
  updateAtribuirPendencia,
  buscarUrlDocumento,
  updateBuscarUrlDocumento,
  confirmarLeitura,
  progressoAnexarDocumento,
  anexarDocumento,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [PENDENCIA_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [PENDENCIA_FIND_RESET]: () => {
      return {
        state: {},
      };
    },
    [PENDENCIA_LOAD_MORE_UPDATE_STORE]: (state, action) => {
      const tarefas = state.tarefas.concat(action.payload.tarefas);
      return {
        ...state,
        tarefas: tarefas,
      };
    },
    [DADOS_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        pendencia: action.payload,
        dadosAdicionais: JSON.parse(action.payload.dadosAdicionais),
        urlBaseGdfront: action.payload.urlBaseGdfront,
        authorization: action.payload.authorization,
      };
    },
    [FINALIZAR_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        gdoc: action.payload.gdoc,
      };
    },
    [ATRIBUIR_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
      };
    },
    [DOCUMENTO_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        urlDocumento: action.payload.urlDocumento,
      };
    },
    [DOCUMENTO_ANEXAR_PROGRESSO]: (state, action) => {
      return {
        ...state,
        progress: action.payload.progress,
      };
    },
    [DOCUMENTO_ANEXAR]: (state, action) => {
      return {
        ...state,
        uploadFileNotAuthorized: true,
      };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const getPendencias = (state) => filter(state);
const getQtdTotal = (state) => getTotal(state);
const getData = (state) => getFinalizacao(state);
const getDadosAdicionaisPendencia = (state) => dadosAdicionaisPendencia(state);
const getUsuarioAtribuido = (state) => usuarioAtribuido(state);
const getTarefa = (state, payload) => getDadosTarefa(state, payload);
const getUrlDocumento = (state) => urlDocumento(state);
const getUrlBaseGdfront = (state) => urlBaseGdfront(state);
const getAuthorization = (state) => authorization(state);
const getProgressoAnexarDocumento = (state) => obterProgressoAnexarDocumento(state);
const getProgressoAnexar = (state) => obterProgressoAnexar(state);

const filter = (state) => {
  return _.values(state.pendencias.tarefas);
};

const getTotal = (state) => {
  return state.pendencias.quantidadeTotal;
};

const getFinalizacao = (state) => {
  return state.pendencias.gdoc;
};

const dadosAdicionaisPendencia = (state) => {
  return state.pendencias.dadosAdicionais;
};

const usuarioAtribuido = (state) => {
  return state.pendencias.usuarioAtribuido;
};

const getDadosTarefa = (state, payload) => {
  if (
    state.pendencias.tarefas === undefined ||
    state.pendencias.tarefas === null
  ) {
    return;
  }
  return state.pendencias.tarefas.find((p) => p.cdTarefa === payload);
};

const obterProgressoAnexarDocumento = (state) => {
  if (state.pendencias.progress === undefined) {
    return 0;
  }
  return state.pendencias.progress;
};

const obterProgressoAnexar = (state) => {
  // PENDENCIA/DOCUMENTO_ANEXAR
  const statesUpload = state.flow ? state.flow[DOCUMENTO_ANEXAR] : {};

  state.uploadFileNotAuthorized = false;
  if(statesUpload && statesUpload.done && statesUpload.erro_mensagem){
    state.uploadFileNotAuthorized = (!statesUpload.loading && statesUpload.erro_mensagem.includes('400'));
  }
  return state.uploadFileNotAuthorized;
};

const urlDocumento = (state) => {
  return state.pendencias.urlDocumento;
};

const urlBaseGdfront = (state) => {
  return state.pendencias.urlBaseGdfront;
};

const authorization = (state) => {
  return state.pendencias.authorization;
};

export const selectors = {
  getPendencias,
  getQtdTotal,
  getData,
  getDadosAdicionaisPendencia,
  getUsuarioAtribuido,
  getTarefa,
  getUrlDocumento,
  getUrlBaseGdfront,
  getAuthorization,
  getProgressoAnexarDocumento,
  getProgressoAnexar
};
