import _ from "lodash";
import { createAction, handleActions } from "redux-actions";

// DOMAIN SPECIFIC CONSTANTS
const APPLICATION_PARAMETERS = "application.parameters";
const APPLICATION_FRONTEND_RESOURCES = "application.resources.frontend";
const APPLICATION_STATIC_STRINGS = "application.static.strings";
const GOOGLE_ANALYTICS_KEY = "google.analytics.key";
const GOOGLE_RECAPTCHA_SITE_KEY = "google.recaptcha.site.key";
const GOOGLE_RECAPTCHA_ENABLED = "google.recaptcha.enabled";
const HOME_INFO = "home.info";
const HTML_HOME = "html.home";
const PASSWORD_VALIDATION = "password.validation";
const UX_MONITORING_DD = "ux.monitoring.dd";
const TIPOS_LOGIN_ATIVOS = "tipos.login.ativos";
const URL_GOVBR = "url.govbr";
const NPS_KEY_ATENDIMENTO = "nps.key"

export const consts = {
  APPLICATION_PARAMETERS,
  APPLICATION_FRONTEND_RESOURCES,
  APPLICATION_STATIC_STRINGS,
  GOOGLE_ANALYTICS_KEY,
  GOOGLE_RECAPTCHA_SITE_KEY,
  GOOGLE_RECAPTCHA_ENABLED,
  HOME_INFO,
  HTML_HOME,
  PASSWORD_VALIDATION,
  UX_MONITORING_DD,
  TIPOS_LOGIN_ATIVOS,
  URL_GOVBR,
  NPS_KEY_ATENDIMENTO,
};

// ACTION TYPES
const ATUALIZAR_TEXTO = `${process.env.PUBLIC_URL}/CONFIGURACOES/ATUALIZAR_TEXTO`;
const ATUALIZAR_JSON = `${process.env.PUBLIC_URL}/CONFIGURACOES/ATUALIZAR_JSON`;
const ATUALIZAR_ARQUIVO = `${process.env.PUBLIC_URL}/CONFIGURACOES/ATUALIZAR_ARQUIVO`;
const ATUALIZAR_EMAIL = `${process.env.PUBLIC_URL}/CONFIGURACOES/ATUALIZAR_EMAIL`;
const CARREGAR = `${process.env.PUBLIC_URL}/CONFIGURACOES/CARREGAR`;
const CARREGAR_CONFIGURACOES_INICIAIS = `${process.env.PUBLIC_URL}/CONFIGURACOES/CARREGAR_CONFIGURACOES_INICIAIS`;
const ATUALIZAR_STORE = `${process.env.PUBLIC_URL}/CONFIGURACOES/ATUALIZAR_STORE`;

export const types = {
  ATUALIZAR_TEXTO,
  ATUALIZAR_JSON,
  ATUALIZAR_ARQUIVO,
  ATUALIZAR_EMAIL,
  CARREGAR,
  CARREGAR_CONFIGURACOES_INICIAIS,
  ATUALIZAR_STORE,
};

// ACTIONS
const atualizarCfgTexto = createAction(ATUALIZAR_TEXTO);
const atualizarCfgJson = createAction(ATUALIZAR_JSON);
const atualizarCfgArquivo = createAction(ATUALIZAR_ARQUIVO);
const atualizarCfgEmail = createAction(ATUALIZAR_EMAIL);
const carregar = createAction(CARREGAR);
const carregarConfiguracoesIniciais = createAction(
  CARREGAR_CONFIGURACOES_INICIAIS,
);
const atualizarStore = createAction(ATUALIZAR_STORE);

export const actions = {
  atualizarCfgTexto,
  atualizarCfgJson,
  atualizarCfgArquivo,
  atualizarCfgEmail,
  carregar,
  carregarConfiguracoesIniciais,
  atualizarStore,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [ATUALIZAR_STORE]: (state, action) => {
      // convertendo de array para object
      const configuracoes = _.keyBy(action.payload, (cfg) => cfg.chave);
      return { ...state, ...configuracoes };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const getValorConfiguracao = (state, chave) => {
  const configuracao = state.configuracoes[chave];

  if (!configuracao) {
    return undefined;
  }

  return configuracao.texto || configuracao.json || configuracao.arquivo;
};

const getConfiguracoes = (state) => {
  return _.values(state.configuracoes || {});
};

const getConfiguracao = (state, chave) => {
  return state.configuracoes[chave] || "";
};

export const selectors = {
  getValorConfiguracao,
  getConfiguracao,
  getConfiguracoes,
};
