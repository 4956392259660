import axios from "../http/axios";

export default {
  find: (payload) => {
    return axios
      .get(
        `/minhas-pendencias?pagina=${payload.pagina}&quantidadePorPagina=${payload.quantidadePorPagina}&sgsTiposTarefaEspecializada=cpav.tarefa.solic.assinatura.ext`,
      )
      .then((res) => {
        return res.data;
      });
  },
  loadMore: (payload) => {
    return axios
      .get(
        `/minhas-pendencias?pagina=${payload.pagina}&quantidadePorPagina=${payload.quantidadePorPagina}&sgsTiposTarefaEspecializada=cpav.tarefa.solic.assinatura.ext`,
      )
      .then((res) => {
        return res.data;
      });
  },
  buscar: (payload) => {
    return axios
      .get(
        `/minhas-pendencias/${payload.cdProcesso}/tarefa/${payload.cdTarefa}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  atribuir: (payload) => {
    return axios
      .put(
        `/minhas-pendencias/tarefa/${payload.cdTarefa}/atribuir`,
        payload.body,
      )
      .then((res) => {
        return res.data;
      });
  },
  finalizar: (payload) => {
    return axios
      .put(
        `/minhas-pendencias/${payload.cdProcesso}/tarefa/${payload.cdTarefa}/finalizar`,
        payload.body,
      )
      .then((res) => {
        return res.data;
      });
  },
  buscarUrlDocumento: (payload) => {
    return axios
      .get(
        `/minhas-pendencias/tarefa/${payload.cdTarefa}/documento-pendente-assinatura/${payload.cdDocumento}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  confirmarLeitura: (payload) => {
    return axios
      .put(`/minhas-pendencias/tarefa/${payload.cdTarefa}/confirma-leitura`)
      .then((res) => {
        return res.data;
      });
  },
};
