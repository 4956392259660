/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import "./language-menu-styles.css";

const LanguageMenu = () => {
  const activatorLanguageMenuRef = useRef(null);
  const dropDownLanguageMenuRef = useRef(null);
  const [menuLanguageOpen, setMenuLanguageOpen] = useState(false);

  const handleMenu = () => {
    setMenuLanguageOpen((menuLanguageOpen) => !menuLanguageOpen);
  };

  const handleClickMenu = () => {
    setMenuLanguageOpen(false);
    activatorLanguageMenuRef.current.focus();
  };

  const keyLanguageMenuHandler = (event) => {
    if (event.key === "Escape" && menuLanguageOpen) {
      setMenuLanguageOpen(false);
      activatorLanguageMenuRef.current.focus();
    }
  };

  const clickOutsideHandler = (event) => {
    if (
      dropDownLanguageMenuRef.current.contains(event.target) ||
      activatorLanguageMenuRef.current.contains(event.target)
    ) {
      return;
    }
    setMenuLanguageOpen(false);
  };

  useEffect(() => {
    if (menuLanguageOpen) {
      dropDownLanguageMenuRef.current.querySelector("button").focus();

      document.addEventListener("mousedown", clickOutsideHandler);
    } else {
      document.removeEventListener("mousedown", clickOutsideHandler);
    }
  }, [menuLanguageOpen]);

  return (
    <React.Fragment>
      <section className="language-menu__container" aria-label="Idioma">
        <div
          className="language-menu__dropdown"
          aria-label="Opções de Idioma"
          onKeyUp={keyLanguageMenuHandler}
        >
          <button
            className="language-menu__button"
            aria-label="Idioma"
            aria-controls="dropdown1"
            onClick={handleMenu}
            ref={activatorLanguageMenuRef}
          >
            <i
              className="material-icons language-menu__icon"
              aria-hidden="true"
            >
              language
            </i>
            <span className="language-menu__title">Idioma</span>
          </button>
          <ul
            id="dropdown1"
            className={`language-menu__submenu ${
              menuLanguageOpen ? "language-menu__dropdown--active" : ""
            }`}
            ref={dropDownLanguageMenuRef}
          >
            <li>
              <button
                className="language-menu__languages"
                onClick={handleClickMenu}
              >
                <span
                  role="img"
                  aria-label="Bandeira do Brasil"
                  aria-hidden="true"
                >
                  🇧🇷
                </span>{" "}
                <span>PT-BR</span>
              </button>
            </li>
          </ul>
        </div>
      </section>
    </React.Fragment>
  );
};

export default LanguageMenu;
