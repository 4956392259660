import path2regex from "path-to-regexp";
import Loadable from "react-loadable";
import Loading from "../containers/Loading";
import Login from "../../App/Visitante/Login/Login";


const DEFAULT_CONTEXT = "";

const Onboarding = Loadable({
  loader: () => import("../containers/Onboarding"),
  loading: Loading,
});

const Status404 = Loadable({
  loader: () => import("../containers/Status404"),
  loading: Loading,
});

const Status429 = Loadable({
  loader: () => import("../containers/Status429"),
  loading: Loading,
});

const TrocarSenha = Loadable({
  loader: () => import("../../App/Usuario/TrocarSenha/TrocarSenha"),
  loading: Loading,
});

const EditarConta = Loadable({
  loader: () => import("../../App/Usuario/EditarConta/EditarConta"),
  loading: Loading,
});

const EditarContaDados = Loadable({
  loader: () => import("../../presentation/pages/edit-account/EditarConta"),
  loading: Loading,
});

const CriarConta = Loadable({
  loader: () => import("../../App/Visitante/CriarConta/CriarConta"),
  loading: Loading,
});

const RecuperarSenha = Loadable({
  loader: () => import("../../App/Visitante/RecuperarSenha/RecuperarSenha"),
  loading: Loading,
});

const RedefinirSenha = Loadable({
  loader: () => import("../../App/Visitante/RedefinirSenha/RedefinirSenha"),
  loading: Loading,
});

const MinhasPendencias = Loadable({
  loader: () =>
    import("../../App/Solicitacoes/MinhasPendencias/MinhasPendencias"),
  loading: Loading,
});

const MeusPagamentos = Loadable({
  loader: () =>
    import("../../App/Solicitacoes/MeusPagamentos/MeusPagamentos"),
  loading: Loading,
});

const MinhasAssinaturasPendentes = Loadable({
  loader: () =>
    import("../../App/Solicitacoes/MinhasAssinaturasPendentes/MinhasAssinaturasPendentes"),
  loading: Loading,
});

const Interacao = Loadable({
  loader: () =>
    import("../../App/Solicitacoes/MinhasPendencias/containers/Interacao"),
  loading: Loading,
});

const InteracaoPagamento = Loadable({
  loader: () =>
    import("../../App/Solicitacoes/MeusPagamentos/containers/Interacao"),
  loading: Loading,
});


const AssinaturaDocumento = Loadable({
  loader: () =>
    import("../../App/Solicitacoes/Interacao/InteracaoAssinaturaDocumento"),
  loading: Loading,
});

const Ativacao = Loadable({
  loader: () => import("../../App/Visitante/Ativacao/Ativacao"),
  loading: Loading,
});

const Configuracoes = Loadable({
  loader: () => import("../../App/Admin/Configuracoes"),
  loading: Loading,
});

const Configuracao = Loadable({
  loader: () => import("../../App/Admin/Configuracao"),
  loading: Loading,
});

const ProcessosLiberados = Loadable({
  loader: () => import("../../presentation/pages/released-processes/released-processes"),
  loading: Loading,
});

const AreasInteresses = Loadable({
  loader: () => import("../../App/Visitante/AreasInteresses/AreasInteresses"),
  loading: Loading,
});

const Servicos = Loadable({
  loader: () => import("../../presentation/pages/list-services-categorys/list-services-categorys"),
  loading: Loading,
});

const ServicoCategoria = Loadable({
  loader: () =>
    import("../../presentation/pages/services-category/services-category"),
  loading: Loading,
});

const SolicitarServico = Loadable({
  loader: () =>
    import("../../presentation/pages/services-info/services-info"),
  loading: Loading,
});

const ServicosDisponiveis = Loadable({
  loader: () => import("../../App/Visitante/Servicos/ServicosDisponiveis"),
  loading: Loading,
});

const Servico = Loadable({
  loader: () => import("../../App/Visitante/Servicos/Servico"),
  loading: Loading,
});

const DiagramaFluxoServico = Loadable({
  loader: () => import("../../App/Visitante/Servicos/DiagramaFluxo/DiagramaFluxo"),
  loading: Loading,
});

const MinhasSolicitacoes = Loadable({
  loader: () =>
    import("../../App/Solicitacoes/MinhasSolicitacoes/MinhasSolicitacoes"),
  loading: Loading,
});

const Solicitacao = Loadable({
  loader: () => import("../../App/Solicitacoes/Solicitacao/Solicitacao"),
  loading: Loading,
});

const Formulario = Loadable({
  loader: () => import("../../App/Solicitacoes/Formulario/Formulario"),
  loading: Loading,
});

const AberturaDeProcesso = Loadable({
  loader: () => import("../../presentation/pages/opening-process/opening-process"),
  loading: Loading,
});

const Finalizacao = Loadable({
  loader: () => import("../../App/Tarefas/Finalizacao/Finalizacao"),
  loading: Loading,
});

const FinalizacaoComuniquese = Loadable({
  loader: () => import("../../App/Tarefas/FinalizacaoComuniquese/FinalizacaoComuniquese"),
  loading: Loading,
});

const FinalizacaoNotificacao = Loadable({
  loader: () => import("../../App/Tarefas/FinalizacaoNotificacao"),
  loading: Loading,
});

const FinalizacaoTarefaFluxo = Loadable({
  loader: () => import("../../App/Tarefas/FinalizacaoTarefaFluxo"),
  loading: Loading,
});

const ConsultaProcesso = Loadable({
  loader: () => import("../../App/Visitante/ConsultaProcesso/ConsultaProcesso"),
  loading: Loading,
});

const ConsultaProcessoGVE = Loadable({
  loader: () =>
    import("../../App/Visitante/ConsultaProcessoGVE/ConsultaProcessoGVE"),
  loading: Loading,
});

const Processo = Loadable({
  loader: () => import("../../App/Visitante/Processo/Processo"),
  loading: Loading,
});

const ConferenciaDocumento = Loadable({
  loader: () =>
    import("../../App/Visitante/ConferenciaDocumento/ConferenciaDocumento"),
  loading: Loading,
});

const Home = Loadable({
  loader: () => import("../../App/Visitante/Home/home"),
  loading: Loading,
});

export const ROUTES = {
  FINALIZACAO: {
    path: `${DEFAULT_CONTEXT}/finalizacao/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:cdTarefa(\\d+)`,
    component: Finalizacao,
    isPrivate: true,
  },
  FINALIZACAO_COMUNIQUESE: {
    path:
      DEFAULT_CONTEXT +
      "/finalizacao-comuniquese/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:cdTarefa(\\d+)",
    component: FinalizacaoComuniquese,
    isPrivate: true,
  },
  FINALIZACAO_NOTIFICACAO: {
    path:
      DEFAULT_CONTEXT +
      "/finalizacao-notificacao/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:cdTarefa(\\d+)",
    component: FinalizacaoNotificacao,
    isPrivate: true,
  },
  FINALIZACAO_TAREFA_FLUXO: {
    path:
      DEFAULT_CONTEXT +
      "/finalizacao-tarefa/:cdTarefa(\\d+)",
    component: FinalizacaoTarefaFluxo,
    isPrivate: true,
  },
  CONFERENCIA_DOCUMENTO: {
    path: `${DEFAULT_CONTEXT}/conferencia-documento`,
    component: ConferenciaDocumento,
    isPrivate: false,
    exact: true,
  },
  CONFERENCIA_DOCUMENTOPORTAL: {
    path: `${DEFAULT_CONTEXT}/conferencia-documento/:chave([\\w\\.=*]+)`,
    component: ConferenciaDocumento,
    isPrivate: false,
    exact: true,
  },
  CONFERENCIA_DOCUMENTOPORTAL_1: {
    path: `${DEFAULT_CONTEXT}/conferenciaDocumentos`,
    component: ConferenciaDocumento,
    isPrivate: false,
    exact: true,
  },
  CONSULTA_PROCESSO: {
    path: `${DEFAULT_CONTEXT}/atendimento`,
    component: ConsultaProcesso,
    isPrivate: false,
    exact: true,
  },
  CONSULTA_PROCESSOGVE: {
    path: `${DEFAULT_CONTEXT}/processo/v1/id/:id`,
    component: ConsultaProcessoGVE,
    isPrivate: false,
    exact: true,
  },
  PROCESSO: {
    path: `${DEFAULT_CONTEXT}/processo/:id`,
    component: Processo,
    isPrivate: false,
    exact: true,
  },
  SOLICITACAO: {
    path: `${DEFAULT_CONTEXT}/solicitacao/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`,
    component: Solicitacao,
    isPrivate: true,
    exact: true,
  },
  FORMULARIO: {
    path: `${DEFAULT_CONTEXT}/servico/:idServico(\\d+)/formulario/:idFormulario(\\d+)`,
    component: Formulario,
    isPrivate: true,
    exact: true,
  },
  MINHAS_SOLICITACOES: {
    path: `${DEFAULT_CONTEXT}/minhas-solicitacoes`,
    component: MinhasSolicitacoes,
    isPrivate: true,
    exact: true,
  },
  PROCESSOS_LIBERADOS: {
    path: `${DEFAULT_CONTEXT}/processos-liberados`,
    component: ProcessosLiberados,
    isPrivate: true,
    exact: true,
  },
  SERVICO: {
    path: `${DEFAULT_CONTEXT}/servico/:id(\\d+)`,
    component: Servico,
    isPrivate: false,
    exact: true,
  },
  SERVICOS_DISPONIVEIS: {
    path: `${DEFAULT_CONTEXT}/areas-interesses/:id(\\d+)`,
    component: ServicosDisponiveis,
    isPrivate: false,
    exact: true,
  },
  AREAS_INTERESSES: {
    path: `${DEFAULT_CONTEXT}/areas-interesses`,
    component: AreasInteresses,
    isPrivate: false,
    exact: true,
  },
  SERVICOS: {
    path: `${DEFAULT_CONTEXT}/servicos`,
    component: Servicos,
    isPrivate: false,
    exact: true,
  },
  CATEGORIA_SERVICOS: {
    path: `${DEFAULT_CONTEXT}/servicos-categoria/:codigo`,
    component: ServicoCategoria,
    isPrivate: false,
    exact: true,
  },
  SERVICO_INFO: {
    path: `${DEFAULT_CONTEXT}/servico-info/:codigo`,
    component: SolicitarServico,
    isPrivate: false,
    exact: true,
  },
  DIAGRAMA_FLUXO_SERVICO: {
    path: `${DEFAULT_CONTEXT}/servico-info/diagrama-fluxo/:id`,
    component: DiagramaFluxoServico,
    isPrivate: false,
    exact: true,
  },
  ABERTURA_PROCESSO: {
    path: `${DEFAULT_CONTEXT}/solicitar-servico/:codigo/formulario/:idForm`,
    component: AberturaDeProcesso,
    isPrivate: true,
    exact: true,
  },
  ONBOARDING: {
    path: `${DEFAULT_CONTEXT}/`,
    component: Onboarding,
    isPrivate: false,
    exact: true,
  },
  MINHAS_ASSINATURAS_PENDENTES: {
    path: DEFAULT_CONTEXT + "/minhas-assinaturas-pendentes",
    component: MinhasAssinaturasPendentes,
    isPrivate: true,
    exact: true,
  },
  MINHAS_PENDENCIAS: {
    path: `${DEFAULT_CONTEXT}/minhas-pendencias`,
    component: MinhasPendencias,
    isPrivate: true,
    exact: true,
  },
  MEUS_PAGAMENTOS: {
    path: `${DEFAULT_CONTEXT}/meus-pagamentos`,
    component: MeusPagamentos,
    isPrivate: true,
    exact: true,
  },
  EDITAR_CONTA: {
    path: `${DEFAULT_CONTEXT}/editar-conta`,
    component: EditarConta,
    isPrivate: true,
    exact: true,
  },
  EDITAR_CONTA_DADOS: {
    path: `${DEFAULT_CONTEXT}/editar-conta-dados`,
    component: EditarContaDados,
    isPrivate: true,
    exact: true,
  },
  TROCAR_SENHA: {
    path: `${DEFAULT_CONTEXT}/trocar-senha`,
    component: TrocarSenha,
    isPrivate: true,
    exact: true,
  },
  INTERACAO: {
    path: `${DEFAULT_CONTEXT}/interacao/:id(\\d+)`,
    component: Interacao,
    isPrivate: true,
    exact: true,
  },
  INTERACAO_PAGAMENTO: {
    path: `${DEFAULT_CONTEXT}/interacao/:id(\\d+)`,
    component: InteracaoPagamento,
    isPrivate: true,
    exact: true,
  },
  ASSINATURA_DOCUMENTO: {
    path: `${DEFAULT_CONTEXT}/assinatura-documento/:id(\\d+)`,
    component: AssinaturaDocumento,
    isPrivate: true,
    exact: true,
    isStandalone: true,
  },
  CONFIGURACOES: {
    path: `${DEFAULT_CONTEXT}/admin/configuracoes`,
    component: Configuracoes,
    isPrivate: true,
    exact: true,
  },
  CONFIGURACAO: {
    path: `${DEFAULT_CONTEXT}/admin/configuracao/:chave([\\w\\.]+)`,
    component: Configuracao,
    isPrivate: true,
    exact: true,
  },
  CRIAR_CONTA: {
    path: `${DEFAULT_CONTEXT}/criar-conta`,
    component: CriarConta,
    isPrivate: false,
    exact: true,
  },
  RECUPERAR_SENHA: {
    path: `${DEFAULT_CONTEXT}/recuperar-senha`,
    component: RecuperarSenha,
    isPrivate: false,
    exact: true,
  },
  REDEFINIR_SENHA: {
    path: `${DEFAULT_CONTEXT}/redefinir-senha/:token([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})`,
    component: RedefinirSenha,
    isPrivate: false,
    exact: true,
  },
  LOGIN: {
    path: `${DEFAULT_CONTEXT}/login`,
    component: Login,
    isPrivate: false,
    exact: true,
  },
  Ativacao: {
    path: `${DEFAULT_CONTEXT}/ativacao/:token([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})`,
    component: Ativacao,
    isPrivate: false,
    exact: true,
  },
  HOME: {
    path: `${DEFAULT_CONTEXT}/inicio`,
    component: Home,
    isPrivate: false,
  },
  STATUS_404: {
    path: `${DEFAULT_CONTEXT}/404`,
    component: Status404,
    isPrivate: false,
  },
  STATUS_429: {
    path: `${DEFAULT_CONTEXT}/429`,
    component: Status429,
    isPrivate: false,
  },
};

export const routeToPath = (route, prms) => {
  var toPath = path2regex.compile(route.path);
  return toPath(prms);
};
