import axios from "../http/axios";

export default {
  start: (req) => {
    return axios.post("/gdoc/sign/start", req).then((res) => {
      return res.data;
    });
  },
  complete: (req) => {
    return axios.post("/gdoc/sign/complete", req).then((res) => {
      return res.data;
    });
  },
};
