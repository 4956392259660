/* eslint-disable no-class-assign */
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classnames from "classnames";

class Separator extends PureComponent {
  render() {
    const { classes, isFullWidth } = this.props;

    return (
      <div
        className={classnames(
          classes.separator,
          isFullWidth ? "" : classes.isNotFullWidth,
        )}
        aria-hidden="true"
      />
    );
  }
}

const styles = () => ({
  separator: {
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
  },
  isNotFullWidth: {
    margin: "0px 24px 0px 24px",
  },
});

Separator.propTypes = {
  isFullWidth: PropTypes.bool.isRequired,
};

Separator.defaultProps = {
  isFullWidth: false,
};

Separator = withStyles(styles)(Separator);

export default Separator;
