import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

class SdsButton extends PureComponent {
  render() {
    const { label, disabled, noFullWidth, ...passthrough } = this.props;

    return (
      <button
        className={classnames(
          "sds-btn sds-btn--raised sds-btn--full-width",
          { "sds-btn--disabled": disabled },
          { "sds-btn--no-full-width": noFullWidth },
        )}
        disabled={disabled}
        {...passthrough}
      >
        <FormattedMessage id={label} />
      </button>
    );
  }
}

SdsButton.propTypes = {
  label: PropTypes.string.isRequired,
};

export default SdsButton;
