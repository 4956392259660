import _ from "lodash";

import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const ASSINATURA_PENDENTE_FIND = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/FIND`;
const ASSINATURA_PENDENTE_UPDATE_STORE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/UPDATE_STORE`;
const ASSINATURA_PENDENTE_LOAD_MORE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/LOAD_MORE`;
const ASSINATURA_PENDENTE_LOAD_MORE_UPDATE_STORE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/LOAD_MORE_UPDATE_STORE`;
const DADOS_ASSINATURA_PENDENTE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/DADOS`;
const DADOS_ASSINATURA_UPDATE_STORE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/DADOS_UPDATE_STORE`;
const FINALIZAR_ASSINATURA_PENDENTE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/FINALIZAR`;
const FINALIZAR_ASSINATURA_PENDENTE_UPDATE_STORE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/FINALIZAR_UPDATE_STORE`;
const ATRIBUIR_ASSINATURA_PENDENTE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/ATRIBUIR`;
const ATRIBUIR_ASSINATURA_PENDENTE_UPDATE_STORE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/ATRIBUIR_UPDATE_STORE`;
const DOCUMENTO_ASSINATURA_PENDENTE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/DOCUMENTO`;
const DOCUMENTO_ASSINATURA_PENDENTE_UPDATE_STORE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/DOCUMENTO_UPDATE_STORE`;
const CONFIRMACAO_LEITURA_ASSINATURA_PENDENTE = `${process.env.PUBLIC_URL}/ASSINATURA_PENDENTE/CONFIRMACAO_LEITURA`;

export const types = {
  ASSINATURA_PENDENTE_FIND,
  ASSINATURA_PENDENTE_UPDATE_STORE,
  ASSINATURA_PENDENTE_LOAD_MORE,
  ASSINATURA_PENDENTE_LOAD_MORE_UPDATE_STORE,
  DADOS_ASSINATURA_PENDENTE,
  DADOS_ASSINATURA_UPDATE_STORE,
  FINALIZAR_ASSINATURA_PENDENTE,
  FINALIZAR_ASSINATURA_PENDENTE_UPDATE_STORE,
  ATRIBUIR_ASSINATURA_PENDENTE,
  ATRIBUIR_ASSINATURA_PENDENTE_UPDATE_STORE,
  DOCUMENTO_ASSINATURA_PENDENTE,
  DOCUMENTO_ASSINATURA_PENDENTE_UPDATE_STORE,
  CONFIRMACAO_LEITURA_ASSINATURA_PENDENTE
};

// ACTIONS
const findAssinaturasPendentes = createAction(ASSINATURA_PENDENTE_FIND);
const updateAssinaturasPendentes = createAction(
  ASSINATURA_PENDENTE_UPDATE_STORE,
);
const loadMore = createAction(ASSINATURA_PENDENTE_LOAD_MORE);
const updateLoadMore = createAction(ASSINATURA_PENDENTE_LOAD_MORE_UPDATE_STORE);
const buscarAssinaturaPendente = createAction(DADOS_ASSINATURA_PENDENTE);
const updateBuscarAssinaturaPendente = createAction(
  DADOS_ASSINATURA_UPDATE_STORE,
);
const finalizarAssinaturaPendente = createAction(FINALIZAR_ASSINATURA_PENDENTE);
const updateFinalizarAssinaturaPendente = createAction(
  FINALIZAR_ASSINATURA_PENDENTE_UPDATE_STORE,
);
const atribuirAssinaturaPendente = createAction(ATRIBUIR_ASSINATURA_PENDENTE);
const updateAtribuirAssinaturaPendente = createAction(
  ATRIBUIR_ASSINATURA_PENDENTE_UPDATE_STORE,
);
const buscarUrlDocumentoAssinaturaPendente = createAction(
  DOCUMENTO_ASSINATURA_PENDENTE,
);
const updateBuscarUrlDocumentoAssinaturaPendente = createAction(
  DOCUMENTO_ASSINATURA_PENDENTE_UPDATE_STORE,
);
const confirmarLeitura = createAction(CONFIRMACAO_LEITURA_ASSINATURA_PENDENTE);


export const actions = {
  findAssinaturasPendentes,
  updateAssinaturasPendentes,
  loadMore,
  updateLoadMore,
  buscarAssinaturaPendente,
  updateBuscarAssinaturaPendente,
  finalizarAssinaturaPendente,
  updateFinalizarAssinaturaPendente,
  atribuirAssinaturaPendente,
  updateAtribuirAssinaturaPendente,
  buscarUrlDocumentoAssinaturaPendente,
  updateBuscarUrlDocumentoAssinaturaPendente,
  confirmarLeitura,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [ASSINATURA_PENDENTE_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [ASSINATURA_PENDENTE_LOAD_MORE_UPDATE_STORE]: (state, action) => {
      // const tarefas = _.merge(state.tarefas, action.payload.tarefas);
      const tarefas = state.tarefas.concat(action.payload.tarefas);

      return {
        ...state,
        tarefas: tarefas,
      };
    },
    [DADOS_ASSINATURA_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        pendencia: action.payload,
        dados: action.payload.itens,
        urlBaseGdfront: action.payload.urlBaseGdfront,
        authorization: action.payload.authorization,
      };
    },
    [FINALIZAR_ASSINATURA_PENDENTE_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        gdoc: action.payload.gdoc,
      };
    },
    [ATRIBUIR_ASSINATURA_PENDENTE_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
      };
    },
    [DOCUMENTO_ASSINATURA_PENDENTE_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        urlDocumento: action.payload.urlDocumento,
      };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const getAssinaturasPendentes = (state) => filter(state);
const getQtdTotal = (state) => getTotal(state);
const getData = (state) => getFinalizacao(state);
const getDadosAssinaturaPendente = (state) => dadosAssinaturaPendente(state);
const getTarefa = (state, payload) => getDadosTarefa(state, payload);
const getUrlDocumento = (state) => urlDocumento(state);
const getUrlBaseGdfront = (state) => urlBaseGdfront(state);
const getAuthorization = (state) => authorization(state);

const filter = (state) => {
  return _.values(state.assinaturasPendentes.tarefas);
};

const getTotal = (state) => {
  return state.assinaturasPendentes.quantidadeTotal;
};

const getFinalizacao = (state) => {
  return state.assinaturasPendentes.gdoc;
};

const dadosAssinaturaPendente = (state) => {
  return state.assinaturasPendentes.dados;
};

const getDadosTarefa = (state, payload) => {
  if (state.assinaturasPendentes.tarefas === undefined) {
    return;
  }
  return state.assinaturasPendentes.tarefas.find((p) => p.cdTarefa === payload);
};

const urlDocumento = (state) => {
  return state.assinaturasPendentes.urlDocumento;
};

const urlBaseGdfront = (state) => {
  return state.assinaturasPendentes.urlBaseGdfront;
};

const authorization = (state) => {
  return state.assinaturasPendentes.authorization;
};

export const selectors = {
  getAssinaturasPendentes,
  getQtdTotal,
  getData,
  getDadosAssinaturaPendente,
  getTarefa,
  getUrlDocumento,
  getUrlBaseGdfront,
  getAuthorization,
};
