import React from "react";
import classnames from "classnames";
import "./menu-icon-styles.css"

const MenuIcon = (props) => {
  return (
    <div className={classnames("icon-menu", {
      "icon-menu-active": props.open,
    })}>
      <svg width="24px" height="24px" aria-hidden="true">
        <path
          d="M5.015625,12.984375 L9,17.015625 L7.59375,18.421875 L1.171875,12 L7.59375,5.578125 L9,6.984375 L5.015625,11.015625 L21,11.015625 L21,12.984375 L5.015625,12.984375 Z M21,6 L21,8.015625 L11.015625,8.015625 L11.015625,6 L21,6 Z M21,15.984375 L21,18 L11.015625,18 L11.015625,15.984375 L21,15.984375 Z"
          id="path-1"
        >
        </path>
      </svg>
    </div>
  );
};

export default MenuIcon;
