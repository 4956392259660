import axios from "../http/axios";

export default {
  findServico: (id) => {
    return axios
      .get(`/v1/servicos/${id}`, { headers: { "Cache-Control": "no-cache" } })
      .then((res) => {
        return res.data;
      });
  },
  findFormularioServico: (data) => {
    return axios
      .get(`/v1/servico/${data.idServico}/formulario/${data.idFormulario}`, {
        headers: { "Cache-Control": "no-cache" },
      })
      .then((res) => {
        return res.data;
      });
  },
};
