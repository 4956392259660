import * as Dialog from "@radix-ui/react-dialog";
import classnames from "classnames";
import React, { PureComponent } from "react";
import { Form } from "redux-form";
import AppLoading from "../../../../AppLayout/containers/AppLoading";
import SoftSigner from "./softplan-websigner/SoftSigner.js";

import "./info-modal-styles.css";

export class AuthSignerDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.signer = new SoftSigner();
    this.myRef = React.createRef();
    this.closeButtonRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    initializing: true,
    certificates: [],
    isExtensionInstalled: false,
    selectedCert: "0",
    password: "",
    user: "",
    mapCertsCpfsUsers: new Map(),
    desafio: "",
    loading: false,
    email: "",
    mapCertsEmailsUsers: new Map(),
    showErrorModal: false,
    showLoginModal: true,
  };

  componentDidMount() {
    this.getCertificates();
  }

  handleOpen = () => {
    if (!this.state.isExtensionInstalled) {
      this.redirectToInstall();
    }
    this.getCertificates();
  };



  getCertificates = async () => {
    try {
      if (this.props.tiposLoginAtivo.includes("certificado-digital")) {
        await this.checkExtensionInstalled();
      }
      const certs = await this.signer.getCertificates();
      const certsCpfsUsers = new Map();
      const certsEmailsUsers = new Map();

      certs.map((certificate) => certsCpfsUsers.set(certificate.thumbprint, certificate.pkiBrazil.cpf));
      certs.map((certificate) => certsEmailsUsers.set(certificate.thumbprint, certificate.email));

      this.setState({
        certificates: certs,
        initializing: false,
        mapCertsCpfsUsers: certsCpfsUsers,
        mapCertsEmailsUsers: certsEmailsUsers,
      });
    } catch (err) {
      console.error(`[error]: ${err}`);
      this.setState({ initializing: false });
    }
  };

  checkExtensionInstalled = () => {
    return new Promise((resolve, reject) => {
      this.signer
        .init({
          notInstalled: () => {
            resolve(false);
          },
        })
        .then(() => {
          this.setState({ isExtensionInstalled: true });
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  };


  redirectToInstall = () => {
    this.signer.redirectToInstallPage();
  };

  focus = () => {
    setTimeout(() => {
      this.myRef.current.focus();
    }, 100);
  };

  handleSetSelectedCert = (e) => {
    this.setState({
      selectedCert: e.target.value,
      user: this.state.mapCertsCpfsUsers.get(e.target.value),
      email: this.state.mapCertsEmailsUsers.get(e.target.value),

    });
    this.handleSigner(e);
  };

  handleOnCloseDialog = () => {
    this.setState({
      selectedCert: "0",
      password: "",
      user: "",
      desafio: "",
      showErrorModal: false,
    });
  };

  handleSubmit = async () => {
    this.setloading(true);
    try {
      let payload = {
        usuario: this.state.user,
        senha: this.state.password,
        certificado: this.state.certificado,
        desafio: this.state.desafio,
        email: this.state.email,
      };

      await this.props.login(payload);
    } catch (err) {
      console.error(`[error]: ${err}`);
    }

    this.setloading(false);
  };

  setloading = (param) => {
    this.setState({
      loading: param,
    });
  };

  setCertificado = (certEncoding) => {
    this.setState({
      certificado: certEncoding,
    });
  };

  setPassword = (hash) => {
    this.setState({
      password: hash,
    });
  };

  handleSigner = async (e) => {
    const randomDesafio = window.btoa(Math.random());

    this.setState({
      desafio: randomDesafio,
    });

    const req = {
      thumbprint: e.target.value,
      data: randomDesafio,
    };

    try {
      this.setCertificado(await this.signer.loadCertificate(e.target.value));
      this.setPassword(await this.signer.signData(req));
      this.handleSubmit();
    } catch (error) {
      console.error(`[error]: ${error}`);
      if (this.closeButtonRef.current) {
        this.closeButtonRef.current.click();
      }
      this.setState({ showErrorModal: true });
    }
  };

  selectOptionFormatter = (cert) => {
    // eslint-disable-next-line no-useless-concat
    let text = cert.subjectName + " (" + "Válido até " + this.formattedDate(cert.validityEnd) + ")";
    if (new Date() > cert.validityEnd) {
      text = "[EXPIRADO] " + text;
    }
    return text;
  };

  formattedDate = (date) => {
    var d = new Date(date || Date.now()),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [month, day, year].join("/");
  };

  render() {
    if (this.state.loading) {
      return <AppLoading show={this.props.loading} />;
    }
    return (
      <React.Fragment>
        <Dialog.Root>
          <Dialog.Trigger asChild>
            {this.props.linkOnly ? (
              <span onClick={async () => {
                this.setloading(true);
                if (!this.state.isExtensionInstalled) {
                  this.handleOpen();
                }
                this.setloading(false);
                this.focus();
              }} tabIndex={9} className="link-style">
                Certificado Digital
              </span>
            ) : (
              <button className="br-button primary mr-3" type="button" label="Entrar com certificado digital"
                      onClick={async () => {
                        this.setloading(true);
                        if (!this.state.isExtensionInstalled) {
                          this.handleOpen();
                        }
                        this.setloading(false);
                        this.focus();
                      }} tabIndex={9}>
                Entrar com Certificado Digital
              </button>
            )}
          </Dialog.Trigger>

          {this.state.showLoginModal && (
            <Form onSubmit={this.handleSubmit}>
              <Dialog.Portal>
                <Dialog.Overlay className="info-modal-overlay" />
                <Dialog.Content className="info-modal-content">
                  <Dialog.Title className="info-modal-title" ref={this.myRef} tabIndex={-1}>
                    Login com Certificado Digital
                  </Dialog.Title>

                  <div>
                    <AppLoading show={this.props.loading} />
                    <section>
                      <div className="groupDiv">
                        <div>
                          <select
                            defaultValue={this.state.selectedCert}
                            className={classnames("sds-select sds-input")}
                            onChange={this.handleSetSelectedCert}>
                            <option value="0">---------------------- Selecione um certificado digital
                              ----------------------
                            </option>
                            {this.state.certificates.map((certificate, index) => (
                              <option key={index} value={certificate.thumbprint}>
                                {this.selectOptionFormatter(certificate)}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div></div>
                      </div>
                    </section>
                    <br />
                    <div className="sds-form-group login-content">
                      <button
                        className="br-button primary mr-3 sds-btn--full-width"
                        label="entrar"
                        tabIndex={9}
                        onClick={this.handleSubmit}
                        disabled={this.state.selectedCert === "0"}>
                        Entrar
                      </button>
                    </div>
                  </div>
                  <Dialog.Close asChild>
                    <button ref={this.closeButtonRef} className="info-modal-button-close" aria-label="Fechar Informações de Contato"
                            onClick={this.handleOnCloseDialog}>
                      <i className="fa fa-times info-modal-icon-close" aria-hidden="true"></i>
                    </button>
                  </Dialog.Close>
                </Dialog.Content>
              </Dialog.Portal>
            </Form>
          )}

          <Dialog.Root open={this.state.showErrorModal}
                       onOpenChange={(open) => this.setState({ showErrorModal: open })}>
            <Dialog.Portal>
              <Dialog.Overlay className="info-modal-overlay" />
              <Dialog.Content className="info-modal-content" onClick={this.handleOnCloseDialog}
                              onInteractOutside={(event) => event.preventDefault()}>
                <Dialog.Title className="info-modal-title">
                  Login com Certificado Digital
                </Dialog.Title>
                <p>Para acessar ou assinar com certificado digital, é necessário permitir a utilização de seus
                  dados.</p>
                <Dialog.Close asChild>
                  <button className="info-modal-button-close" aria-label="Fechar" onClick={this.handleOnCloseDialog}>
                    Fechar
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>

        </Dialog.Root>
      </React.Fragment>
    );
  }
}

export default AuthSignerDialog;