import axios from "../../commons/http/axios";

export default {
  cadastro: (req) => {
    return axios.post("/usuario/cadastro", req).then((res) => {
      return res.data;
    });
  },
  get: () => {
    return axios.get("/usuario").then((res) => {
      return res.data;
    });
  },
  update: () => {
    return axios.get("/usuario/update").then((res) => {
      return res.data;
    });
  },
  updateGov: () => {
    return axios.get("/usuario/updateGov").then((res) => {
      return res.data;
    });
  },
  login: (req) => {
    return axios.post(`/login`, req).then((res) => {
      return res.data;
    });
  },
  loginGovBr: () => {
    return axios.post(`/login/govbr`).then((res) => {
      return res.data;
    });
  },
  logout: () => {
    return axios.get("/logout").then((res) => {
      return res.data;
    });
  },
  recuperarSenha: (req) => {
    return axios.post("/usuario/recuperar-senha", req).then((res) => {
      return res.data;
    });
  },
  redefinirSenha: (token, body) => {
    return axios.post(`/usuario/redefinir-senha/${token}`, body).then((res) => {
      return res.data;
    });
  },
  alterarSenha: (req) => {
    return axios.post("/usuario/alterar-senha", req).then((res) => {
      return res.data;
    });
  },
  alterarUsuario: (body) => {
    return axios.post("/usuario/alterar-usuario", body).then((res) => {
      return res.data;
    });
  },
  verificarIdentificador: (req) => {
    return axios.post("/usuario/verificar-identificador", req).then((res) => {
      return res.data;
    });
  },
  buscarDadosParaCompletarCadastro: (req) => {
    return axios.post("/usuario/buscar-dados-para-completar-cadastro", req).then((res) => {
      return res.data;
    });
  },
  buscarDadosParaEditarCadastro: (req) => {
    return axios.post("/usuario/buscar-dados-para-editar-cadastro", req).then((res) => {
      return res.data;
    });
  },
  editarCadastro: (req) => {
    return axios.post("/usuario/editar-cadastro", req).then((res) => {
      return res.data;
    });
  },
};
