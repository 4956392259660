import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Layout from "./containers/Layout";

import {
  consts as cfgConsts,
  selectors as cfgSelectors,
} from "../../redux/modules/configuracoes";

import {
  actions as userActions,
  selectors as userSelectors,
} from "../../redux/modules/usuario";

class AppLayout extends Component {
  componentDidMount() {
    document.getElementById("skip-to-content").focus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      document.getElementById("skip-to-content").focus();
    }
  }

  render() {
    const { children, logout, isAuthenticated, isUserAdmin, isLoginGov, isLoginAuth, token, perfils, isCadIncompleto, urlGovBr} =
      this.props;

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <Layout
        isAuthenticated={isAuthenticated}
        isUserAdmin={isUserAdmin}
        isLoginGov={isLoginGov}
        isLoginAuth={isLoginAuth}
        urlGovBr={urlGovBr}
        isCadIncompleto={isCadIncompleto}
        token={token}
        logout={logout}
        perfils={perfils}
      >
        {children}
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: userSelectors.isAuthenticated(state),
    isUserAdmin: userSelectors.isUserInRole(state, "ROLE_ADMIN"),
    isLoginGov: userSelectors.isLoginGov(state),
    isLoginAuth: userSelectors.isLoginAuth(state),
    isCadIncompleto: userSelectors.isCadIncompleto(state),
    token: userSelectors.getAccessToken(state),
    perfils: userSelectors.getUser(state).perfils,
    urlGovBr: cfgSelectors.getValorConfiguracao(
      state,
      cfgConsts.URL_GOVBR,
    ),
  };
}

const mapDispatchToProps = {
  logout: userActions.logoutUser,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(AppLayout);
