import PropTypes from "prop-types";
import React, { PureComponent } from "react";

class SdsAlert extends PureComponent {
  render() {
    const { msg } = this.props;

    if (!msg) {
      return null;
    }

    return (
      <div className="sds-form-group sds-block sds-block--alert">
        <p
          className="sds-p sds-color-alert"
          dangerouslySetInnerHTML={{ __html: msg }}
          aria-live="assertive"
          role="alert"
        ></p>
      </div>
    );
  }
}

SdsAlert.propTypes = {
  msg: PropTypes.string,
};

export default SdsAlert;
