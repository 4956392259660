import _ from "lodash";

import { createAction, handleActions } from "redux-actions";

// DOMAIN SPECIFIC CONSTANTS
const STATUS_SOLICITACAO_EM_ANDAMENTO = "EM_ANDAMENTO";
const STATUS_SOLICITACAO_FINALIZADA = "FINALIZADA";
const STATUS_SOLICITACAO_CANCELADA = "CANCELADA";
const STATUS_INTERACAO_EM_ANDAMENTO = "EM_ANDAMENTO";
const STATUS_INTERACAO_FINALIZADA = "FINALIZADA";
const STATUS_INTERACAO_CONCLUIDA = "CONCLUIDO";
const STATUS_INTERACAO_CANCELADA = "CANCELADA";

export const consts = {
  STATUS_SOLICITACAO_EM_ANDAMENTO,
  STATUS_SOLICITACAO_FINALIZADA,
  STATUS_SOLICITACAO_CANCELADA,
  STATUS_INTERACAO_EM_ANDAMENTO,
  STATUS_INTERACAO_FINALIZADA,
  STATUS_INTERACAO_CONCLUIDA,
  STATUS_INTERACAO_CANCELADA,
};

// ACTION TYPES
const SOLICITACAO_FIND = `${process.env.PUBLIC_URL}/SOLICITACAO/FIND`;
const SOLICITACAO_FIND_ALL = `${process.env.PUBLIC_URL}/SOLICITACAO/FIND_ALL`;
const SOLICITACAO_FINALIZAR = `${process.env.PUBLIC_URL}/SOLICITACAO/FINALIZAR`;
const SOLICITACAO_UPDATE_STORE = `${process.env.PUBLIC_URL}/SOLICITACAO/UPDATE_STORE`;
const SOLICITACAO_UPDATE_ALL_STORE = `${process.env.PUBLIC_URL}/SOLICITACAO/UPDATE_ALL_STORE`;
const SOLICITACAO_LIST = `${process.env.PUBLIC_URL}/SOLICITACAO/LIST`;
const SOLICITACAO_LIST_STORE = `${process.env.PUBLIC_URL}/SOLICITACAO/LIST_STORE`;
const SOLICITACAO_RESET = `${process.env.PUBLIC_URL}/SOLICITACAO/RESET`;

export const types = {
  SOLICITACAO_FIND,
  SOLICITACAO_FIND_ALL,
  SOLICITACAO_FINALIZAR,
  SOLICITACAO_UPDATE_STORE,
  SOLICITACAO_UPDATE_ALL_STORE,
  SOLICITACAO_LIST,
  SOLICITACAO_LIST_STORE,
  SOLICITACAO_RESET,
};

// ACTIONS
const findSolicitacao = createAction(SOLICITACAO_FIND);
const findSolicitacoes = createAction(SOLICITACAO_FIND_ALL);
const finalizarSolicitacao = createAction(SOLICITACAO_FINALIZAR);
const updateSolicitacaoStore = createAction(SOLICITACAO_UPDATE_STORE);
const updateAllSolicitacaoStore = createAction(SOLICITACAO_UPDATE_ALL_STORE);
const listSolicitacoes = createAction(SOLICITACAO_LIST);
const updateListSolicitacoes = createAction(SOLICITACAO_LIST_STORE);
const reset = createAction(SOLICITACAO_RESET);

export const actions = {
  findSolicitacoes,
  findSolicitacao,
  finalizarSolicitacao,
  updateSolicitacaoStore,
  updateAllSolicitacaoStore,
  listSolicitacoes,
  updateListSolicitacoes,
  reset,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [SOLICITACAO_UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    [SOLICITACAO_UPDATE_ALL_STORE]: (state, action) => {
      return {
        ...state,
        solicitacoesEmAndamento: action.payload.andamento.solicitacoes,
        countAndamentos: action.payload.andamento.total,
        solicitacoesFinalizadas: action.payload.concluida.solicitacoes,
        countFinalizadas: action.payload.concluida.total,
      };
    },
    [SOLICITACAO_LIST_STORE]: (state, action) => {
      let solicitacoesEmAndamento = state.solicitacoesEmAndamento;
      let solicitacoesFinalizadas = state.solicitacoesFinalizadas;
      if (action.payload.andamento) {
        solicitacoesEmAndamento = _.merge(
          state.solicitacoesEmAndamento,
          _.keyBy(
            action.payload.andamento.solicitacoes,
            (solicitacao) => solicitacao.codigo,
          ),
        );
      } else {
        solicitacoesFinalizadas = _.merge(
          state.solicitacoesFinalizadas,
          _.keyBy(
            action.payload.concluida.solicitacoes,
            (solicitacao) => solicitacao.codigo,
          ),
        );
      }

      return {
        ...state,
        solicitacoesEmAndamento: solicitacoesEmAndamento,
        solicitacoesFinalizadas: solicitacoesFinalizadas,
      };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const getSolicitacoesEmAndamento = (state) => filterEmAndamento(state);
const getSolicitacoesFinalizadas = (state) => filterFinalizadas(state);
const getSolicitacoes = (state, status) => filter(state, status);
const getSolicitacoesByStatus = (state, status) =>
  _.size(filter(state, status));
const getInteracoesByStatus = (state, status) =>
  filterInteracoes(state, status);
const getCountInteracoesByStatus = (state, status) =>
  _.size(getInteracoesByStatus(state, status));
const getInteracaoById = (state, codigo) => findInteracao(state, codigo);
const getCountSolicitacoesEmAndamento = (state) => countEmAndamento(state);
const getCountSolicitacoesFinalizadas = (state) => countFinalizadas(state);
const getSizeSolicitacoesEmAndamento = (state) =>
  _.size(filterEmAndamento(state));
const getSizeSolicitacoesFinalizadas = (state) =>
  _.size(filterFinalizadas(state));
const getSolicitacao = (state) => state.solicitacoes;

const filterEmAndamento = (state) => {
  return _.flatMap(_.values(state.solicitacoes.solicitacoesEmAndamento)).filter(
    (i) => i !== undefined,
  );
};

const filterFinalizadas = (state) => {
  return _.flatMap(_.values(state.solicitacoes.solicitacoesFinalizadas)).filter(
    (i) => i !== undefined,
  );
};

const filter = (state, status) => {
  return _.flatMap(_.values(state.solicitacoes))
    .filter((i) => i.status === status)
    .filter((i) => i !== undefined);
};

const filterInteracoes = (state, status) => {
  return _.flatMap(_.values(state.solicitacoes), (s) =>
    _.values(s.interacoes),
  ).filter((i) => i.status === status);
};

const findInteracao = (state, codigo) => {
  return _.flatMap(_.values(state.solicitacoes), (s) =>
    _.values(s.interacoes),
  ).find((i) => i.codigo === Number(codigo));
};

const countEmAndamento = (state) => {
  return state.solicitacoes.countAndamentos;
};

const countFinalizadas = (state) => {
  return state.solicitacoes.countFinalizadas;
};

export const selectors = {
  getSolicitacoesEmAndamento,
  getSolicitacoesFinalizadas,
  getSolicitacoes,
  getSolicitacoesByStatus,
  getInteracoesByStatus,
  getCountInteracoesByStatus,
  getInteracaoById,
  getCountSolicitacoesEmAndamento,
  getCountSolicitacoesFinalizadas,
  getSizeSolicitacoesEmAndamento,
  getSizeSolicitacoesFinalizadas,
  getSolicitacao,
};
