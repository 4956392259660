import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form, Field, reduxForm, isValid, isSubmitting } from "redux-form";

import { ROUTES } from "../../../../../commons/routes/routes";
import AppLoading from "../../../../AppLayout/containers/AppLoading";
import SdsError from "../../../../../commons/containers/SdsError";
import SdsInput from "../../../../../commons/containers/SdsInput";
import SdsInputPassword from "../../../../../commons/containers/SdsInputPassword";
import Typography from "@material-ui/core/Typography";
import { obrigatorio, formatCpfCnpj, validateCpfCnpj } from "../../../../../commons/validation/validation";
import SdsButton from "../../../../../commons/containers/SdsButton";
import { selectors as usuarioSelectors, types as usuarioTypes } from "../../../../../redux/modules/usuario";
import { selectors as flowSelectors } from "../../../../../redux/modules/flow";
import { ReCaptchaComponent } from "../../../../../commons/components/ReCaptcha/ReCaptchaComponent";
import ReCAPTCHA from "react-google-recaptcha";
import { imgHome } from "../../../../../utils/constants";
import { IsEmpty } from "../../../../../utils/functions/IsEmpty";
import { handleVerifyEmptyField } from "../../../../../utils/functions/GeneralFunctions";
import AuthSignerDialog from "../../components/AuthSignerDialog";

import "./login-gov-styles.css";
import "./login-auth-styles.css";
import classNames from "classnames";
import SdsAlert from "../../../../../commons/containers/SdsAlert";

class LoginAuth extends ReCaptchaComponent {
  state = {
    identificacao: "",
    senha: "",
    comCertificado: "",
    tipoLoginDestaque: "",
  };

  handleInputChange = (event) => {
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleClickLogin = () => {
    const senha = this.state.senha;
    let payload = {
      usuario: this.state.identificacao,
      senha: IsEmpty(senha) ? senha : senha.toLowerCase(),
      redirectUrl: this.props.redirectUrl,
      captcha: this.getReCaptcha(),
      comCertificado: this.state.comCertificado,
    };

    this.props.login(payload);
    this.resetReCaptcha();
  };

  handleVerifyEmptyIdField = (e) => {
    const { intl } = this.props;

    handleVerifyEmptyField(e, intl, "LABELS.COMMONS.USUARIO.IDENTIFICACAO");
  };

  handleVerifyEmptyPasswordField = (e) => {
    const { intl } = this.props;

    handleVerifyEmptyField(e, intl, "LABELS.COMMONS.USUARIO.SENHA");
  };

  componentDidMount() {
    const btnId = document.getElementById("identificacao");
    btnId.addEventListener("blur", this.handleVerifyEmptyIdField);

    const btnPassword = document.getElementById("senha");
    btnPassword.addEventListener("blur", this.handleVerifyEmptyPasswordField);

    if(this.props.tiposLoginAtivo) {
      this.setState({tipoLoginDestaque: this.props.tiposLoginAtivo.split(",")[0]});
    }
  }

  componentWillUnmount() {
    const btnId = document.getElementById("identificacao");
    btnId.addEventListener("blur", this.handleVerifyEmptyIdField);

    const btnPassword = document.getElementById("senha");
    btnPassword.addEventListener("blur", this.handleVerifyEmptyPasswordField);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tiposLoginAtivo !== this.props.tiposLoginAtivo) {
      this.setState({tipoLoginDestaque: this.props.tiposLoginAtivo.split(",")[0]});
    }
  }

  render() {
    const { data, done, erro, loading, valid, submitting, handleSubmit, captchaSitekey, captchaEnabled, tiposLoginAtivo, login, msgAlert } = this.props;

    const logins = tiposLoginAtivo ? tiposLoginAtivo : "auth";

    const fnHandleSubmit = captchaEnabled ? this.validateReCaptcha : this.handleClickLogin;

    const handleClickGov = function (event) {
      window.open(
        `/portal-externo-oauth-server/oauth2/authorize/govbr?redirect_uri=${window.location.origin}${process.env.PUBLIC_URL}/oauth2/redirect`,
        "_self",
      );
    };

    const error = (done && data && data.mensagemErro)  ? data.mensagemErro : erro ? erro : null;

    return (
      <section className="login-content">
        <img src={imgHome} className="login-image-bg" alt="" />
        <section className="login-container" aria-label="Login">
          <SdsAlert msg={msgAlert} />
          <div className="divisor" />
          <div className="main-area">
            <div className="login-destaque-area">
              <div className="govbr-area" style={{ display: logins.includes("govbr") && this.state.tipoLoginDestaque === "govbr" ? "block" : "none" }}>
                <AppLoading show={loading} msg="LABELS.COMMONS.LOGIN.LOADING" />
                <SdsError msg={erro} />
                <Typography variant="body2" align="center" gutterBottom>
                  <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo-govbr.png`} width="150px" tabIndex={7} alt="Logo gov.br." />
                </Typography>
                <div className="sds-form-group descricao">
                  <span tabIndex={8}>
                    <FormattedMessage id="LABELS.COMMONS.LOGIN.GOVBR.DESCRICAO" />
                  </span>
                </div>
                <Form onSubmit={handleSubmit(fnHandleSubmit)}>
                  <div className="sds-form-group login-content">
                    <button className="br-button primary mr-3" type="button" onClick={handleClickGov}>
                      <FormattedMessage id="LABELS.COMMONS.LOGIN.GOVBR" />
                    </button>
                  </div>
                </Form>
              </div>
              <div style={{ display: logins.includes("auth") && this.state.tipoLoginDestaque !== "govbr" ? "block" : "none" }}>
                <div className="sds-row sds-center-xs login-space">
                  <AppLoading show={loading} msg="LABELS.COMMONS.LOGIN.LOADING" />
                  <Form onSubmit={handleSubmit(fnHandleSubmit)} className="sds-input-format login-form-container">
                    <SdsError msg={error} />
                    <Field
                      autoFocus
                      name="identificacao"
                      label="LABELS.COMMONS.USUARIO.IDENTIFICACAO"
                      type="text"
                      component={SdsInput}
                      value={this.state.identificacao}
                      onChange={this.handleInputChange}
                      validate={[obrigatorio, validateCpfCnpj]}
                      normalize={formatCpfCnpj}
                      required={true}
                    />

                    <Field
                      name="senha"
                      type="password"
                      component={SdsInputPassword}
                      value={this.state.senha}
                      onChange={this.handleInputChange}
                      label="LABELS.COMMONS.USUARIO.SENHA"
                      validate={[obrigatorio]}
                      required={true}
                    />

                    {captchaEnabled && (
                      <ReCAPTCHA ref={this.recaptchaRef} size={"invisible"} sitekey={captchaSitekey} onChange={this.handleClickLogin} />
                    )}

                    <div className="sds-form-group sds-button-format">
                      <SdsButton type="submit" label="LABELS.COMMONS.LOGIN.ENTRAR" disabled={!valid || submitting} />
                    </div>
                  </Form>
                  <div className="sds-row sds-center-xs">
                    <div className="sds-col-xs-12">
                      <Link
                        to={{
                          pathname: ROUTES.RECUPERAR_SENHA.path,
                          state: { identificacao: this.state.identificacao },
                        }}
                        className="sds-link"
                        aria-label="Recuperar Senha">
                        <FormattedMessage id="LABELS.COMMONS.LOGIN.ESQUECEU_A_SENHA" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="sds-margin-vertical sds-row sds-center-xs">
                  <div className="sds-col-xs-12">
                    <p className="sds-p">
                      <FormattedMessage
                        id="LABELS.COMMONS.LOGIN.NAO_POSSUI_CONTA"
                        values={{
                          "route-to-criar-conta": (
                            <Link to={ROUTES.CRIAR_CONTA.path} className="sds-link" aria-label="Criar Conta">
                              <FormattedMessage id="LABELS.COMMONS.LOGIN.CRIAR_CONTA" />
                            </Link>
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {logins.split(",").length >= 2 && (
              <div class="hr-sect login-space">
                <FormattedMessage id="LABELS.COMMONS.OU" />
              </div>
            )}
            
            <div className={classNames("tipos-login", {
                "justify-center": logins.split(",").length === 2,
              })}
            >
              {this.state.tipoLoginDestaque !== "govbr" && logins.includes("govbr") && (
                <Form onSubmit={handleSubmit(fnHandleSubmit)}>
                  <div className="login-content">
                    <button className="br-button primary" style={{ padding: "0 20px" }} type="button" onClick={handleClickGov}>
                      <FormattedMessage id="LABELS.COMMONS.LOGIN.GOVBR" />
                    </button>
                  </div>
                </Form>
              )}
              {logins.includes("certificado-digital") && (
                <>
                  <div className="login-content" style={{ color: "#135c96" }}>
                    <i className="material-icons sds-btn__icon small-icon-link">key</i>
                    <AuthSignerDialog loading={loading} login={login} tiposLoginAtivo={tiposLoginAtivo} linkOnly={true} />
                  </div>
                  <SdsError msg={erro} />
                </>
              )}
              {this.state.tipoLoginDestaque !== "auth" && logins.includes("auth") && (
                <div className="login-content">
                  <i className="material-icons sds-btn__icon small-icon-link">lock_open</i>
                  <span onClick={() => this.setState({tipoLoginDestaque: "auth"})} className="link-style">
                    Login e senha do sistema
                  </span>
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
    );
  }
}

LoginAuth.defaultProps = {
  erro: undefined,
  redirectUrl: undefined,
};

LoginAuth.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string,
  erro: PropTypes.string,
  tiposLoginAtivo: PropTypes.string.isRequired,
};

const FORM_ID = "form_login";

function mapStateToProps(state) {
  return {
    data: usuarioSelectors.getUser(state),
    done: flowSelectors.isDoneByType(state, usuarioTypes.USER_LOGIN),
    valid: isValid(FORM_ID)(state),
    submitting: isSubmitting(FORM_ID)(state),
  };
}

export default compose(connect(mapStateToProps), reduxForm({ form: FORM_ID }), injectIntl)(LoginAuth);
