import React from "react";
class LoadExternalScript extends React.Component {
    static defaultProps = {
        onLoad: () => {},
        onError: () => {},
    };

    render() {
        const {props} = this;

        if (!document.getElementById(props.type)) {
            const script = document.createElement("script");
            script.type = props.type;
            script.text = props.text;
            script.onload = props.onLoad;
            script.onerror = props.onError;

            if (document.body) {
                document.body.appendChild(script);
            }
        }

        if (!document.getElementById(props.id)) {
            const script = document.createElement("script");
            script.src = props.src;
            script.id = props.id;
            script.onload = props.onLoad;
            script.onerror = props.onError;

            if (document.body) {
                document.body.appendChild(script);
            }
        } else {
            props.onError("script already loaded");
        }

        return false;
    }
}

export default LoadExternalScript;
