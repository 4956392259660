import axios from "../http/axios";

export default {
  find: (payload) => {
    let urlFiltros = "";
    if (payload.pagina) {
      urlFiltros += `&pagina=${payload.pagina}`;
    }
    if (payload.quantidadePorPagina) {
      urlFiltros += `&quantidadePorPagina=${payload.quantidadePorPagina}`;
    }
    if (payload.dtPrazoInicio) {
      urlFiltros += `&dtPrazoInicio=${payload.dtPrazoInicio}`;
    }
    if (payload.dtPrazoFim) {
      urlFiltros += `&dtPrazoFim=${payload.dtPrazoFim}`;
    }
    if (payload.situacoes) {
      urlFiltros += `&situacoes=${payload.situacoes}`;
    }
    if (payload.sgsTiposTarefaEspecializada) {
      urlFiltros += `&sgsTiposTarefaEspecializada=${payload.sgsTiposTarefaEspecializada}`;
    }
    if (payload.cdTarefa) {
      urlFiltros += `&cdTarefa=${payload.cdTarefa}`;
    }
    if (payload.ordenacao) {
      urlFiltros += `&ordenacao=${payload.ordenacao}`;
    }
    return axios
      .get(`/meus-pagamentos?usuario=${payload.usuario}${urlFiltros}`)
      .then((res) => {
        return res.data;
      });
  },
  loadMore: (payload) => {
    return axios
      .get(
        `/meus-pagamentos?pagina=${payload.pagina}&quantidadePorPagina=${payload.quantidadePorPagina}&usuario=${payload.usuario}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  buscarPagamento: (payload) => {
    return axios
      .get(
        `/meus-pagamentos/${payload.cdProcesso}/tarefa/${payload.cdTarefa}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  atribuir: (payload) => {
    return axios
      .put(
        `/meus-pagamentos/${payload.cdProcesso}/tarefa/${payload.cdTarefa}/atribuir/${payload.usuario}`,
        payload.body,
      )
      .then((res) => {
        return res.data;
      });
  },
  finalizar: (payload) => {
    return axios
      .put(
        `/meus-pagamentos/${payload.cdProcesso}/tarefa/${payload.cdTarefa}/finalizar/${payload.usuario}`,
        payload.body,
      )
      .then((res) => {
        return res.data;
      });
  },
  buscarUrlDocumento: (payload) => {
    return axios
      .get(
        `/meus-pagamentos/tarefa/${payload.cdTarefa}/documento-pendente-assinatura/${payload.cdDocumento}/${payload.usuario}`,
      )
      .then((res) => {
        return res.data;
      });
  },
  anexarDocumento: (payload, config) => {
    const formData = new FormData();
    formData.append("files", payload.file);
    return axios.post(`/godoc/archiving`, formData, config);
  },
  confirmarLeitura: (payload) => {
    return axios
      .put(`/meus-pagamentos/tarefa/${payload.cdTarefa}/confirma-leitura`)
      .then((res) => {
        return res.data;
      });
  },
};
