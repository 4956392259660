import { all, call, put, takeEvery } from "redux-saga/effects";
import assinaturasPendentesService from "../../commons/services/assinaturas_pendentes";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/assinaturasPendentes";
import { actions as modalActions } from "../../redux/modules/modal";

export default function* assinaturasPendentesSaga() {
  yield takeEvery(types.ASSINATURA_PENDENTE_FIND, findAssinaturasPendentes);
  yield takeEvery(types.ASSINATURA_PENDENTE_LOAD_MORE, loadMore);
  yield takeEvery(
    types.FINALIZAR_ASSINATURA_PENDENTE,
    finalizarAssinaturaPendente,
  );
  yield takeEvery(types.DADOS_ASSINATURA_PENDENTE, buscarAssinaturaPendente);
  yield takeEvery(
    types.ATRIBUIR_ASSINATURA_PENDENTE,
    atribuirAssinaturaPendente,
  );
  yield takeEvery(
    types.DOCUMENTO_ASSINATURA_PENDENTE,
    buscarUrlDocumentoAssinaturaPendente,
  );
  yield takeEvery(
    types.CONFIRMACAO_LEITURA_ASSINATURA_PENDENTE,
    confirmarLeitura,
  );
}

function* findAssinaturasPendentes(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => assinaturasPendentesService.find(action.payload || [])),
      ]);

      yield put(actions.updateAssinaturasPendentes(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* loadMore(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => assinaturasPendentesService.loadMore(action.payload || [])),
      ]);

      yield put(actions.updateLoadMore(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* finalizarAssinaturaPendente(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => assinaturasPendentesService.finalizar(action.payload)),
      ]);

      yield put(actions.updateFinalizarAssinaturaPendente(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },
  });
}

function* buscarAssinaturaPendente(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => assinaturasPendentesService.buscar(action.payload || [])),
      ]);

      yield put(actions.updateBuscarAssinaturaPendente(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}

function* atribuirAssinaturaPendente(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => assinaturasPendentesService.atribuir(action.payload)),
      ]);

      yield put(actions.updateAtribuirAssinaturaPendente(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },
  });
}

function* buscarUrlDocumentoAssinaturaPendente(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() =>
          assinaturasPendentesService.buscarUrlDocumento(action.payload || []),
        ),
      ]);

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}


function* confirmarLeitura(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => assinaturasPendentesService.confirmarLeitura(action.payload || [])),
      ]);

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback(data);
      }
    },

    fnCatch: function* () {
      yield put(
        modalActions.open({
          title: "LABELS.COMMONS.CONFIRMACAO_LEITURA.ERRO.TITULO",
          content: "LABELS.COMMONS.CONFIRMACAO_LEITURA.ERRO.DESCRICAO",
          params: {},
        }),
      );
    },
  });
}

