import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

class AppLoading extends PureComponent {
  render() {
    const { show, msg, transparent, isPositionRelative } = this.props;

    const position = isPositionRelative ? "relative" : "absolute";

    if (!show) {
      return null;
    }

    return (
      <div
        className={classnames("sds-load", {
          "sds-load--transparent": transparent,
        })}
        style={{position : position}}
      >
        <div className="sds-load__content">
          <svg
            width="3rem"
            height="3rem"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              stroke="#666666"
              strokeWidth="4"
              r="40"
              strokeDasharray="164.93361431346415 56.97787143782138"
            />
          </svg>
          {msg && (
            <p className="sds-p">
              <FormattedMessage id={msg} />
            </p>
          )}
        </div>
      </div>
    );
  }
}

AppLoading.propTypes = {
  show: PropTypes.bool.isRequired,
  msg: PropTypes.string,
  transparent: PropTypes.bool,
};

export default AppLoading;
