import axios from "../http/axios";

export default {
  searchOrgao: (payload) => {
    return axios
      .get(
        `/v1/orgao?sgOrgao=${payload.sgOrgao}&nmOrgao=${payload.nmOrgao}&pagina=${payload.pagina}`,
      )
      .then((res) => {
        return res.data;
      });
  },
};
