import React, { PureComponent } from "react";
import LoadExternalScript from "../helper/LoadExternalScript";

class Tidio extends PureComponent {

    onTidioChatApiReady = () => {
        if (window.tidioChatApi) {
            window.tidioChatApi.show();
            window.tidioChatApi.on("close", () => {
                window.tidioChatApi.show();
            });
        }
    };

    onLoaded = () => {
        if (window.tidioChatApi) {
            window.tidioChatApi.on("ready", this.onTidioChatApiReady());
        } else {
            document.addEventListener('tidioChat-ready', this.onTidioChatApiReady());
        }
    }

    render() {
        return (
            <>
                {this.props.mdChatClient && <LoadExternalScript
                    type="text/javascript"
                    text={`var mdChatClient="${this.props.mdChatClient}"`}
                    onLoad={() => this.onLoaded()}
                />
                }

                <LoadExternalScript
                    src={this.props.url}
                    id="tidio-chat-script"
                    onLoad={() => this.onLoaded()}
                />
            </>
        );
    }
}

export default Tidio;
