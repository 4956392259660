import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
const ADDRESS_SEARCH = `${process.env.PUBLIC_URL}/ADDRESS/SEARCH`;
const UPDATE_STORE = `${process.env.PUBLIC_URL}/ADDRESS/UPDATE_STORE`;

export const types = {
  ADDRESS_SEARCH,
  UPDATE_STORE,
};

// ACTIONS
const searchAddress = createAction(ADDRESS_SEARCH);
const updateAddressStore = createAction(UPDATE_STORE);

export const actions = {
  searchAddress,
  updateAddressStore,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [UPDATE_STORE]: (state, action) => {
      return {
        ...state,
        endereco: action.payload,
      };
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const getAddress = (state) => state.endereco.endereco;

export const selectors = {
  getAddress,
};
