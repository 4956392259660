import axios from "../http/axios";

export default {
  searchInteressado: (payload) => {
    return axios
      .get(
        `/v1/interessado?nuInteressado=${payload.nuInteressado}&nmInteressado=${payload.nmInteressado}&pagina=${payload.pagina}`,
      )
      .then((res) => {
        return res.data;
      });
  },
};
