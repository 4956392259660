import { all, call, put, takeEvery } from "redux-saga/effects";
import assuntoService from "../../commons/services/assunto";
import { flow } from "../modules/flow";
import { actions, types } from "../modules/assunto";

export default function* assuntoSaga() {
  yield takeEvery(types.ASSUNTO_SEARCH, searchAssunto);
}

function* searchAssunto(action) {
  yield* flow({
    type: action.type,

    fnTry: function* () {
      const [data] = yield all([
        call(() => assuntoService.searchAssunto(action.payload || [])),
      ]);

      yield put(actions.updateAssunto(data));

      const { callback } = action.payload;

      // callback
      if (callback && typeof callback === "function") {
        callback();
      }
    },

    fnCatch: function* () {
      // não exibir mensagem de erro...
    },
  });
}
